import React from "react";
import Image from "next/image";
import { IconWithLabelProps } from "./types";
import classes from "./IconWithLabel.module.scss";

export const IconWithLabel = ({ option }: IconWithLabelProps) => {
    return (
        <div className={classes.IconLabel}>
            <div className={classes.IconLabel__wrapper}>
                <Image
                    src={`${process.env.NEXT_PUBLIC_YANDEX_STORAGE_URL}${option.alias}.png`}
                    alt={option.label}
                    fill={true}
                    unoptimized={true}
                    onError={(e) => {
                        e.currentTarget.src = "/img/placeholder-brand.png";
                        e.currentTarget.srcset = "/img/placeholder-brand.png";
                    }}
                />
            </div>
            <span className={classes.IconLabel__name}>{option.label}</span>
        </div>
    );
};