import Link from "next/link";
import React from "react";
import BreadCrumbsIcon from "../../Svg/BreadCrumbsIcon";
import { BreadCrumbsProps } from "./types";
import classes from "./BreadCrumbs.module.scss";

const BreadCrumbs = ({ pageTitle }: BreadCrumbsProps) => {
    return (
        <div className={classes.BreadCrumbs}>
            <ul>
                <li>
                    <Link href={"/"}>Главная</Link>
                    <BreadCrumbsIcon />
                </li>
                <li>
                    <Link href={"/catalog"}>Каталог</Link>
                    <BreadCrumbsIcon />
                </li>
                <li>{pageTitle || "VIII (XV70)"}</li>
            </ul>
        </div>
    );
};
export default BreadCrumbs;