import { isValidEmail } from "@src/utils/utils";

export const emailValidationRules = {
    required: 'Заполните поле "Почта"',
    validate: (email: string) => {
        const isValid = isValidEmail(email);
        return isValid ? isValid : "Неверный формат почты";
    }
};

export const nameValidationRules = {
    required: 'Заполните поле "Имя"',
    minLength: {
        value: 3,
        message: "Минимальная длина имени 3 символа"
    }
};

export const phoneValidationRules = {
    minLength: {
        value: 11,
        message: "Номер телефона должен содержать 11 цифр"
    }
};

export const messageValidationRules = {
    required: 'Заполните поле "Сообщение"',
    minLength: {
        value: 30,
        message: `Поле "сообщение" должно содержать минимум 30 символов`
    }
};