import React from "react";
import classes from "./text-area-input.module.scss";
import { TextInputProps } from "./text-area-input.interface";
import { numberWithSpaces } from "@utils/utils";

const TextAreaInput = ({ type: inType = "text", ...props }: TextInputProps) => {
    let value = props.value || "";
    if (inType === "number") {
        value = numberWithSpaces(value);
    }

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onChange) {
            if (inType === "number") {
                const val = e.target.value;
                if (val === "") {
                    props.onChange(undefined);
                } else {
                    const res = val.replace(/\D/g, "");
                    props.onChange(res ? parseInt(res) : undefined);
                }
            } else {
                const val = e.target.value;
                props.onChange(val);
            }
        }
    };

    return (
        <div className={classes.TextInput} style={props.contStyle}>
            <textarea
                value={value || ""}
                disabled={props.disabled}
                style={props.style}
                placeholder={props.placeholder}
                onChange={onChange}
                inputMode={inType === "number" ? "numeric" : undefined}
                id={props.id}
                maxLength={props.maxLength}
            />
        </div>
    );
};
export default TextAreaInput;