import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const InsuranceIcon: FC<IconProps> = ({ className, color }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.3542 12.1752L13.9115 9.62728C14.1146 9.42786 14.3865 9.31583 14.6698 9.31583C14.9521 9.31583 15.224 9.42786 15.424 9.62728C15.624 9.82669 15.7365 10.0972 15.7365 10.3792C15.7365 10.6612 15.624 10.9317 15.424 11.1311L12.8667 13.6764C12.6479 13.8946 12.3885 14.0678 12.1031 14.186C11.8167 14.3041 11.5104 14.3649 11.201 14.3649C10.8917 14.3649 10.5854 14.3041 10.3 14.186C10.0135 14.0678 9.75417 13.8946 9.53646 13.6764L8.58125 12.7252C8.47813 12.6274 8.39583 12.51 8.33854 12.38C8.28229 12.25 8.25208 12.1099 8.25 11.968C8.24792 11.8262 8.275 11.6855 8.32917 11.5541C8.36354 11.4681 8.41042 11.3872 8.46667 11.3135C8.49688 11.2747 8.52917 11.2378 8.56354 11.2032C8.66354 11.103 8.78333 11.0239 8.91563 10.9706C9.04792 10.9173 9.18854 10.8908 9.33125 10.8927C9.47396 10.8947 9.61458 10.925 9.74479 10.9819C9.875 11.0388 9.99271 11.1211 10.0906 11.2241L11.0479 12.1752H11.3542Z"
            fill={color || "#1D1D1B"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 13.9792C20 15.6371 19.0719 16.7158 18.6271 17.2313C18.0104 17.9486 17.4052 18.5066 16.0563 19.3568C15.5333 19.6889 14.8458 20.0768 14.0083 20.5125C13.3865 20.8328 12.6969 21 11.9969 21C11.2979 21 10.6083 20.8328 9.98646 20.5125C9.15729 20.0794 8.47708 19.6889 7.94375 19.3568C6.57292 18.488 5.97604 17.93 5.44271 17.3083L5.43646 17.3006C4.93021 16.7197 4 15.651 4 13.9819V7.85514C4 7.25264 4.18646 6.6648 4.53438 6.17207C4.88229 5.67935 5.375 5.30581 5.94375 5.1026C7.8875 4.40261 9.93438 4.02998 12 4C14.0656 4.03135 16.1125 4.40395 18.0563 5.1026C18.625 5.30566 19.1167 5.67879 19.4646 6.17098C19.8125 6.66319 20 7.25044 20 7.85248V13.9792ZM12.0042 18.8715C12.3594 18.8715 12.7094 18.7893 13.0271 18.6314C13.7948 18.2303 14.4292 17.8716 14.9146 17.566C16.0479 16.8513 16.4938 16.4475 16.9333 15.9374C17.3729 15.4272 17.8667 14.8534 17.8667 13.9792V7.84982C17.8667 7.7639 17.8521 7.67912 17.825 7.59862C17.8 7.52503 17.7646 7.45502 17.7188 7.39096C17.624 7.25682 17.4885 7.15539 17.3333 7.10058C15.6219 6.48496 13.8198 6.15549 12 6.1255C10.1802 6.15675 8.37813 6.48804 6.66667 7.10589C6.51146 7.16071 6.37604 7.26214 6.28125 7.39627C6.18542 7.53041 6.13333 7.69069 6.13333 7.85514V13.9819C6.13333 14.853 6.61667 15.4105 6.9875 15.8363L7 15.8523C7.475 16.4076 7.94896 16.846 9.09375 17.5687C9.57604 17.8716 10.2135 18.2276 10.9813 18.6314C11.299 18.7893 11.649 18.8715 12.0042 18.8715Z"
            fill={color || "#1D1D1B"}
        />
    </svg>
);