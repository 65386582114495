import React from "react";
import { TG_AUTH_ID } from "@features/auth/constants";
import { configApi } from "@shared/lib/configApi";
import { TgLoginIcon } from "@shared/ui/Icon/ui/AuthServices/TgLoginIcon";
export const TgLogin = () => {
    const paramsTg = {
        bot_id: TG_AUTH_ID,
        origin: String(configApi.API_PUBLIC_DOMAIN),
        embed: 1,
        request_access: "write",
        return_to: `${configApi.API_PUBLIC_DOMAIN}/auth/tg`
    };

    const tgUrl =
        "https://oauth.telegram.org/auth?" +
        Object.entries(paramsTg)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join("&");

    if (!TG_AUTH_ID || !configApi.API_PUBLIC_DOMAIN) {
        console.error("Не найдены переменные окружения для Телеграмм!");
        return null;
    }

    return (
        <a href={tgUrl}>
            <TgLoginIcon />
        </a>
    );
};