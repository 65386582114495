import React from "react";
import { ProductPropsProps } from "./types";
import classes from "./ProductProps.module.scss";

const ProductProps = ({ offer }: ProductPropsProps) => {
    return (
        <div className={classes.ProductProps}>
            <h3>Характеристики</h3>

            <div className={classes.Props}>
                {offer.props.map((prop, index) => {
                    return (
                        <div key={index} className={classes.Prop}>
                            <div className={classes.PropName}>{prop.label}</div>
                            <div className={classes.PropValue}>{prop.value}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default ProductProps;