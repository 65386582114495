import { getAccessToken } from "@shared/api/getAccessToken";
import { COOKIE_MAX_AGE_SECONDS, COOKIE_PATH, COUNT_VIEWS_OFFER, MAX_VIEWS } from "./constants";

export const updateCounterViews = (): number => {
    if (navigator.cookieEnabled) {
        const token = getAccessToken();
        if (token) {
            document.cookie = `${COUNT_VIEWS_OFFER}=0;path=${COOKIE_PATH};max-age=${COOKIE_MAX_AGE_SECONDS}`;
            return 0;
        }
        const getCookieValue = (name: string): string | null => {
            const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
            return match ? decodeURIComponent(match[2]) : null;
        };

        let views = parseInt(getCookieValue(COUNT_VIEWS_OFFER) || "0", 10);

        views += 1;
        if (views > MAX_VIEWS) {
            views = 1;
        }
        document.cookie = `${COUNT_VIEWS_OFFER}=${views};path=${COOKIE_PATH};max-age=${COOKIE_MAX_AGE_SECONDS}`;
        return views;
    }

    return 0;
};