import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import classNames from "classnames";
import { useGetProductPriceUsdQuery, useUpdateOfferPriceUsdMutation } from "@app/store/offersApi";
import { numberWithSpaces } from "@src/utils/utils";
import { getCurrencyCode } from "@features/product/ProductPriceUsd/lib/getCurrencyCode";
import { RefreshIcon } from "@shared/ui/Icon/ui/Common/RefreshIcon";
import { ProductPriceUsdProps } from "./types";
import { BUTTON_UPDATE_TITLE, DEFAULT_CURRENCY, TIMEOUT_ANIMATION_BUTTON } from "./constants";
import classes from "./ProductPriceUsd.module.scss";

export const ProductPriceUsd = ({
    className,
    price = 0,
    priceUsd,
    sourceId,
    createdAt,
    updatedAt,
    isUpdated = false,
    offerUuid
}: ProductPriceUsdProps) => {
    const [fallbackPrice, setFallbackPrice] = useState<number | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const currencyCode = sourceId ? getCurrencyCode(sourceId) : DEFAULT_CURRENCY;
    const shouldSkip = priceUsd !== 0 || price === undefined;
    const lastUpdateDateTime = DateTime.fromISO(updatedAt ?? createdAt ?? "");
    const currentDate = DateTime.now();
    const isDateToday = lastUpdateDateTime.hasSame(currentDate, "day");

    const tooltipText = isUpdated
        ? `Примерная стоимость в USD по курсу ${currencyCode} от ${lastUpdateDateTime.toFormat("dd.MM.yyyy")}`
        : undefined;

    const { data, error, isFetching } = useGetProductPriceUsdQuery(
        { currencyCode, price: price },
        { skip: shouldSkip }
    );

    const [updateOfferPriceUsd] = useUpdateOfferPriceUsdMutation();

    const showHidePrice =
        isFetching || isAnimating || (fallbackPrice === null && priceUsd === undefined);
    const showUpdateButton = isUpdated && !isDateToday;

    const displayPrice =
        priceUsd !== 0
            ? `~ ${numberWithSpaces(priceUsd.toString())} $`
            : fallbackPrice !== null
              ? `~ ${numberWithSpaces(fallbackPrice.toString())} $`
              : "";

    const handleUpdateClick = async () => {
        if (typeof offerUuid === "string") {
            setIsAnimating(true);
            updateOfferPriceUsd(offerUuid);
            setTimeout(() => setIsAnimating(false), TIMEOUT_ANIMATION_BUTTON);
        }
    };

    useEffect(() => {
        if (data) {
            setFallbackPrice(data);
        }
    }, [data]);

    if (error) {
        return null;
    }

    return (
        <div className={classes["product-price"]}>
            <span
                className={classNames(className, classes["product-price__inner"], {
                    [classes["product-price__inner-hidden"]]: showHidePrice
                })}
                title={tooltipText}
            >
                {displayPrice}
            </span>
            {showUpdateButton && (
                <button
                    className={classes["update-button"]}
                    onClick={handleUpdateClick}
                    disabled={isFetching}
                    title={BUTTON_UPDATE_TITLE}
                >
                    <RefreshIcon
                        className={classNames(classes["update-button__icon"], {
                            [classes["update-button__icon-rotate"]]: isAnimating
                        })}
                    />
                </button>
            )}
        </div>
    );
};