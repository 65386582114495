import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import CodeInput from "../CodeInput/CodeInput";
import { SeoTargets } from "@src/services/SeoTargets";
import { AppDispatch, AppState } from "@app/store/store";
import { clearErrors, fastLogin } from "@app/store/authSlice";
import { formatToPhone } from "@src/utils/utils";
import { PrimaryButton } from "@components/UI/Buttons/PrimaryButton/PrimaryButton";
import { showEnterByEmail } from "@app/store/layoutSlice";
import { CloseIcon } from "@shared/ui/Icon/ui/Common/CloseIcon";
import { LikviIcon } from "@shared/ui/Icon/ui/Boards/LikviIcon";
import { SocialAuth } from "./SocialAuth";
import { PrivacyInfo } from "./PrivacyInfo";
import { ResendCode } from "./ResendCode";
import { EnterByPhoneFormProps } from "./types";
import classes from "./EnterByPhoneForm.module.scss";

const EnterByPhoneForm = ({ onClose }: EnterByPhoneFormProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const isLoggingIn = useSelector((state: AppState) => state.auth.isLoggingIn);
    const enterFormMessage = useSelector((state: AppState) => state.layout.enterFormMessage);

    const [isCodeSent, setIsCodeSent] = useState(false);
    const [currentCode, setCurrentCode] = React.useState("");
    const [codeError, setCodeError] = React.useState("");
    const [sendAttempt, setSendAttempt] = useState(0);
    const [isEntering, setIsEntering] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        reset
    } = useForm();

    const onSubmit = async (e: any) => {
        e.preventDefault();
        SeoTargets.enterByPhoneEvent();
        if (isCodeSent) {
            if (!currentCode || currentCode.length != 6) {
                setCodeError("Неверный код подтверждения");
            }
        } else {
            const submit = handleSubmit(async () => {
                sendCode();
            });
            submit();
        }
    };

    const showError = (errName: string) => {
        const errMsg = errors[errName]?.message;
        return (
            <span className={classes.EnterByPhoneForm__error}>{errMsg && errMsg.toString()}</span>
        );
    };

    const sendCode = () => {
        setIsCodeSent(true);
        setCodeError("");
        reset({ RegPhone: getValues("RegPhone") });
        const phone = getValues("RegPhone");
        dispatch(
            fastLogin({
                phone: phone
            })
        );
        setSendAttempt((attempt) => attempt + 1);
    };

    const fastEnter = async () => {
        setIsEntering(true);
        setCodeError("");
        const phone = getValues("RegPhone");
        const res = await dispatch(
            fastLogin({
                phone: phone.replace("+", ""),
                password: currentCode
            })
        );
        if (res.type === "auth/fastLogin/fulfilled") {
            onClose();
        } else {
            setCodeError("Неверный код подтверждения");
        }
        setIsEntering(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onSubmit(event);
        }
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onSubmit(e);
    };

    const handleEmailMethodClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(showEnterByEmail());
    };

    useEffect(() => {
        dispatch(clearErrors());
        if (currentCode) {
            fastEnter();
        }
    }, [currentCode]);

    return (
        <div className={classes.EnterByPhoneForm} onKeyDown={handleKeyDown}>
            <CloseIcon className={classes.EnterByPhoneForm__close} onClick={onClose} />
            {enterFormMessage ? (
                <div className={classes.EnterByPhoneForm__message}>
                    <LikviIcon className={classes.EnterByPhoneForm__icon} />
                    <p>{enterFormMessage}</p>
                </div>
            ) : (
                <h3 className={classes.EnterByPhoneForm__title}>Вход или регистрация</h3>
            )}
            <form>
                {isCodeSent && (
                    <>
                        <p className={classes.EnterByPhoneForm__container}>
                            Мы отправили код на телефон:
                            <br />
                            <b>{formatToPhone(getValues("RegPhone"))}</b>
                        </p>
                        <label className={classes["EnterByPhoneForm__sent-text"]}>
                            Введите код:
                        </label>
                        <CodeInput
                            key={sendAttempt}
                            onChange={(code) => {
                                setCurrentCode(code);
                            }}
                        />
                        {codeError && (
                            <span className={classes["EnterByPhoneForm__error-code"]}>
                                {codeError}
                            </span>
                        )}
                    </>
                )}
                {!isCodeSent && (
                    <>
                        <div className={classes["EnterByPhoneForm__input-phone"]}>
                            <label className={classes["EnterByPhoneForm__input-phone--text"]}>
                                Телефон
                            </label>
                            <Controller
                                control={control}
                                name="RegPhone"
                                rules={{
                                    required: 'Заполните поле "Телефон"',
                                    minLength: {
                                        value: 11,
                                        message: "Номер телефона должен содержать 11 цифр"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        placeholder="+7 (999) 123 45 67"
                                        country={"ru"}
                                        inputProps={{
                                            autoFocus: false,
                                            required: true
                                        }}
                                        value={value}
                                        onChange={(phone) => onChange(phone)}
                                    />
                                )}
                            />
                            {errors.RegPhone && showError("RegPhone")}
                        </div>

                        {!isCodeSent && (
                            <PrimaryButton
                                className={classes.EnterByPhoneForm__button}
                                isLoading={isLoggingIn}
                                onClick={handleButtonClick}
                                content="Получить код"
                            />
                        )}
                        <span
                            className={classes["EnterByPhoneForm__email-mode"]}
                            onClick={handleEmailMethodClick}
                        >
                            Другой способ
                        </span>
                    </>
                )}
                <SocialAuth />
                {!isCodeSent && <PrivacyInfo />}
            </form>
            {isCodeSent && (
                <PrimaryButton
                    className={classes.EnterByPhoneForm__button}
                    isLoading={isEntering}
                    onClick={handleButtonClick}
                    content="Далее"
                />
            )}
            {isCodeSent && <ResendCode sendCode={sendCode} />}
        </div>
    );
};
export default EnterByPhoneForm;