import React from "react";
import classNames from "classnames";
import { RouterConfig } from "@shared/lib/routerConfig";
import { AutoIcon } from "@shared/ui/Icon/ui/Navigation/AutoIcon";
import { DocumentsIcon } from "@shared/ui/Icon/ui/Navigation/DocumentsIcon";
import { InsuranceIcon } from "@shared/ui/Icon/ui/Navigation/InsuranceIcon";
import { MainMenuButton } from "./MainMenuButton";
import { DesktopMenuProps } from "./types";
import classes from "./DesktopMenu.module.scss";

const DesktopMenu = ({ activeItem, className }: DesktopMenuProps) => {
    /* TODO Скрываем Dropdown меню и секцию будущих фич до подключения Маркетплейсы, Работа
           https://jira.gfun.club/browse/LIKVI-1346
    const dispatch = useDispatch();
    */

    return (
        <div className={classNames(classes.DesktopMenu, className)}>
            <div>
                <MainMenuButton
                    title="Авто"
                    icon={AutoIcon}
                    link={RouterConfig.CATALOG}
                    isActive={activeItem.link === RouterConfig.CATALOG}
                />
            </div>
            <div>
                <MainMenuButton
                    title="Страхование"
                    icon={InsuranceIcon}
                    link={RouterConfig.INSURANCE}
                    isActive={activeItem.link === RouterConfig.INSURANCE}
                />
            </div>
            {/* TODO: Временно скрываем cсылки на оценку авто. Для подробностей см. задачу LIKVI-1346 в Jira.
            <div>
                <MainMenuButton
                    title="Оценка авто"
                    icon={EstimateIcon}
                    link={RouterConfig.ANALYTICS}
                    isActive={activeItem.link === RouterConfig.ANALYTICS}
                />
            </div>
             */}
            <div>
                <MainMenuButton
                    title="Документы"
                    icon={DocumentsIcon}
                    link={RouterConfig.GENERATOR_DKP}
                    isActive={activeItem.link === RouterConfig.GENERATOR_DKP}
                />
            </div>
            {/* TODO Скрываем Dropdown меню и секцию будучих фич до подключения Маркетплейсы, Работа
            https://jira.gfun.club/browse/LIKVI-1346
            <MainMenuDropdown title="Разное" items={dropdownItems} activeItem={activeItem.link} />
            <div
                className={classes.ClosedItems}
                onClick={() => {
                    dispatch(setIsSoonVisible(true));
                }}
            >
                <div>
                    <MainMenuButton
                        disabled={true}
                        title="Маркетплейсы"
                        icon={MarketsIcon}
                        link={RouterConfig.MARKETPLACES}
                        isActive={activeItem.link === RouterConfig.MARKETPLACES}
                    />
                </div>
                <div>
                    <MainMenuButton
                        disabled={true}
                        title="Работа"
                        icon={JobIcon}
                        link={RouterConfig.JOB}
                        isActive={activeItem.link === RouterConfig.JOB}
                    />
                </div>
            </div>
            */}
        </div>
    );
};
export default DesktopMenu;