import Link from "next/link";
import { useRouter } from "next/router";
import { MenuButtonProps } from "./types";
import classes from "../Header.module.scss";

const MenuButton = (props: MenuButtonProps) => {
    const isDisabled = props.disabled;
    const router = useRouter();
    const link = props.link || "/";
    const isActive = router.pathname === link;
    const iconOnly = !props.title && !!props.icon;
    if (isDisabled) {
        return (
            <a
                onClick={(e) => {
                    e.preventDefault();
                }}
                id={props.id}
                className={`${classes.MenuButton} ${iconOnly ? classes.IconButton : null} ${isActive ? classes.Acctive : null} ${props.className} ${classes.Disabled}`}
            >
                {props.icon && props.icon}
                {props.title && <span>{props.title}</span>}
            </a>
        );
    }
    if (props.onClick) {
        return (
            <a
                onClick={(e) => {
                    e.preventDefault();
                    props.onClick?.();
                }}
                id={props.id}
                className={`${classes.MenuButton} ${iconOnly ? classes.IconButton : null} ${isActive ? classes.Active : null} ${props.className}`}
            >
                {props.icon && props.icon}
                {props.title && <span style={props.spanStyle}>{props.title}</span>}
            </a>
        );
    } else {
        return (
            <Link legacyBehavior href={link} className={props.className}>
                <a
                    className={`${classes.MenuButton} ${iconOnly ? classes.IconButton : null} ${isActive ? classes.Active : null}`}
                    aria-label={props.ariaLabel}
                >
                    {props.icon && props.icon}
                    {props.title && <span style={props.labelStyle}>{props.title}</span>}
                </a>
            </Link>
        );
    }
};

export default MenuButton;