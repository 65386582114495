import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";
export interface FavoriteIconProps extends SvgInterface {
    isSelected?: boolean;
}
export default React.memo(function FavoriteIcon({ isSelected = false }: FavoriteIconProps) {
    return (
        <>
            {!isSelected && (
                <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="36" height="36" rx="8" fill="#1D1D1B" fillOpacity="0.5" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.208 10.7002C25.0921 9.58464 23.5947 8.97269 22.0433 9.00094C20.479 9.00172 19.0566 10.0112 18.0192 11.1407C16.971 10.0107 15.53 9.00172 13.9567 9.00094C12.4052 8.97269 10.9078 9.58464 9.79196 10.7002C8.67594 11.8158 8.0324 13.3442 8 14.9497V14.9537C8 16.5591 8.6284 18.0048 9.64795 19.0751L9.92311 19.3398L17.332 26.7085C17.7078 27.0973 18.3323 27.0972 18.708 26.7083L26.0863 19.3301C26.6861 18.7834 27.1667 18.1107 27.4965 17.3572C27.8268 16.6021 27.9985 15.7829 28 14.954L27.9999 14.9497C27.9675 13.3442 27.324 11.8158 26.208 10.7002ZM26.0535 14.9554C26.053 16.1292 25.5404 17.1954 24.732 17.9367L18.0196 24.6382L11.2836 17.9421L11.0638 17.7344C10.7113 17.3717 10.4308 16.9397 10.2389 16.463C10.0467 15.9857 9.94722 15.4732 9.94643 14.9554C9.95658 14.4177 10.0684 13.8875 10.2754 13.3951C10.4827 12.9021 10.7812 12.4569 11.1534 12.0847C11.5256 11.7125 11.9643 11.4206 12.4442 11.2253C12.9241 11.03 13.4361 10.935 13.9509 10.9454H13.9549C14.6063 10.9454 15.2293 11.2441 15.7948 11.6902C16.3592 12.1354 16.8502 12.715 17.2331 13.2456C17.4197 13.5042 17.708 13.6476 18.0189 13.6479C18.1731 13.6493 18.3252 13.6136 18.4632 13.5441C18.6016 13.4745 18.7218 13.373 18.8149 13.2481L18.8174 13.2446C19.196 12.7132 19.6747 12.1339 20.2278 11.689C20.7816 11.2436 21.3942 10.9454 22.0451 10.9454L22.0491 10.9454C22.5639 10.9349 23.0758 11.03 23.5557 11.2253C24.0356 11.4206 24.4743 11.7125 24.8465 12.0847C25.2188 12.4569 25.5172 12.9021 25.7245 13.3951C25.9315 13.8875 26.0434 14.4177 26.0535 14.9554Z"
                        fill="white"
                    />
                </svg>
            )}

            {isSelected && (
                <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="36" height="36" rx="8" fill="#1D1D1B" fillOpacity="0.5" />
                    <g clipPath="url(#clip0_140_2455)">
                        <g filter="url(#filter0_d_140_2455)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M26.208 10.7002C25.0921 9.58464 23.5947 8.97269 22.0433 9.00094C20.479 9.00172 19.0566 10.0112 18.0192 11.1407C16.971 10.0107 15.53 9.00172 13.9567 9.00094C12.4052 8.97269 10.9078 9.58464 9.79196 10.7002C8.67594 11.8158 8.0324 13.3442 8 14.9497V14.9537C8 16.5591 8.6284 18.0048 9.64795 19.0751L9.92311 19.3398L17.332 26.7085C17.7078 27.0972 18.3323 27.0972 18.708 26.7083L26.0863 19.3301C26.6861 18.7834 27.1667 18.1107 27.4965 17.3572C27.8268 16.6021 27.9985 15.7829 28 14.954L27.9999 14.9497C27.9675 13.3442 27.324 11.8158 26.208 10.7002Z"
                                fill="white"
                            />
                        </g>
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_140_2455"
                            x="5"
                            y="8"
                            width="26"
                            height="24"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_140_2455"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_140_2455"
                                result="shape"
                            />
                        </filter>
                        <clipPath id="clip0_140_2455">
                            <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </>
    );
});