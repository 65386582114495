import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const CloseIcon: FC<IconProps> = ({ className, onClick }) => (
    <svg
        className={className}
        onClick={onClick}
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.692 1.79511C14.1027 1.38445 14.1027 0.718653 13.692 0.307993C13.2814 -0.102656 12.6155 -0.102661 12.2049 0.307993L7 5.51289L1.79511 0.307993C1.38445 -0.102667 0.718647 -0.102661 0.307993 0.307993C-0.102661 0.718647 -0.102667 1.38445 0.307993 1.79511L5.51289 7L0.307993 12.2049C-0.102661 12.6155 -0.102667 13.2813 0.307993 13.692C0.718653 14.1027 1.38445 14.1027 1.79511 13.692L7 8.48711L12.2049 13.692C12.6156 14.1027 13.2814 14.1027 13.692 13.692C14.1027 13.2814 14.1027 12.6156 13.692 12.2049L8.48711 7L13.692 1.79511Z"
            fill="#F86A6A"
        />
    </svg>
);