import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

export default React.memo(function GalleryNextIcon({ ...other }: SvgInterface) {
    return (
        <svg
            className={other.className}
            onClick={other.onClick}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="32"
                height="32"
                rx="16"
                transform="matrix(1 1.74846e-07 1.74846e-07 -1 0 32)"
                fill="white"
                fillOpacity="0.51"
            />
            <path
                d="M14.4343 10.5657C14.7467 10.2533 15.2533 10.2533 15.5657 10.5657L20.0912 15.0912C20.4036 15.4036 20.4036 15.9101 20.0912 16.2225L15.5657 20.748C15.2533 21.0604 14.7467 21.0604 14.4343 20.748C14.1219 20.4356 14.1219 19.9291 14.4343 19.6167L18.3941 15.6569L14.4343 11.6971C14.1219 11.3846 14.1219 10.8781 14.4343 10.5657Z"
                fill="#343B47"
            />
        </svg>
    );
});