import React, { FC } from "react";

export const ShareIcon: FC<{ className?: string }> = ({ className }) => (
    <svg
        className={className}
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.5028 0.160271C10.3971 0.0747654 10.2694 0.0209236 10.1344 0.00497365C9.9994 -0.0109763 9.86264 0.0116173 9.73995 0.0701411C9.61725 0.128665 9.51363 0.220728 9.44107 0.335681C9.3685 0.450634 9.32997 0.583781 9.32991 0.71972V3.62497C3.38838 4.00369 0 8.93433 0 17.28C0.000302012 17.4568 0.0656316 17.6273 0.183541 17.759C0.301451 17.8907 0.463699 17.9744 0.63937 17.9942C0.666096 17.9979 0.693034 17.9998 0.720012 18C0.883284 18 1.04169 17.9444 1.16925 17.8425C1.29681 17.7406 1.38594 17.5983 1.42202 17.4391C1.4753 17.2051 2.76628 11.895 9.32919 10.8978V13.6799C9.32925 13.8159 9.36778 13.949 9.44035 14.064C9.51291 14.1789 9.61653 14.271 9.73923 14.3295C9.86193 14.388 9.99868 14.4106 10.1337 14.3947C10.2687 14.3787 10.3964 14.3249 10.5021 14.2394L18.5122 7.75927C18.5955 7.69177 18.6627 7.60651 18.7089 7.50972C18.755 7.41293 18.779 7.30706 18.779 7.19983C18.779 7.09259 18.755 6.98672 18.7089 6.88993C18.6627 6.79314 18.5955 6.70788 18.5122 6.64038L10.5028 0.160271ZM10.7699 12.1715V10.0835C10.7704 9.98308 10.7498 9.8837 10.7095 9.79173C10.6693 9.69976 10.6102 9.61722 10.5362 9.54942C10.4622 9.48161 10.3748 9.43004 10.2796 9.39802C10.1844 9.36599 10.0837 9.35422 9.98368 9.36346C8.36163 9.4502 6.77811 9.89027 5.3439 10.6529C3.90968 11.4155 2.6594 12.4823 1.68051 13.7786C2.28964 9.60466 4.27903 5.05635 10.0521 5.03979C10.243 5.03979 10.4262 4.96393 10.5612 4.8289C10.6962 4.69388 10.7721 4.51074 10.7721 4.31978V2.23174L16.9152 7.19983L10.7699 12.1715Z"
            fill="#7B869B"
        />
    </svg>
);