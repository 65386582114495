import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const DocumentsIcon: FC<IconProps> = ({ className, color }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
    >
        <path
            fill={color || "#1D1D1B"}
            d="M14.335 4H8a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8.665a2.002 2.002 0 0 0-.585-1.415L15.75 4.585A2 2 0 0 0 14.335 4ZM17 17a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6.335L17 8.665V17Z"
        />
        <path
            fill={color || "#1D1D1B"}
            d="M14 13.5h-4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2ZM10 9.5a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4Z"
        />
    </svg>
);