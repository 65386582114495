import React, { useEffect, useState } from "react";
import classes from "./CardChart.module.scss";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Text
} from "recharts";
import { useGetAnalyticsForOfferQuery } from "../../../app/store/analyticsApi";
import { ApiChartResponseSchema } from "../scheme";
import { DateTime } from "luxon";
import { capitalize } from "lodash";
import SelectInput from "../../../components/UI/Inputs/SelectInput/SelectInput";
import { TimeSort } from "../../../components/Features/Catalog/catalogUtils";
import { numberWithSpaces } from "../../../utils/utils";
import useIsMobile from "@src/hooks/isMobile";
import { CardChartProps, ChartData } from "./types";

const CardChart = ({ offer, maxSlices = 6 }: CardChartProps) => {
    const options = [
        { value: TimeSort.Week, label: "По неделям" },
        { value: TimeSort.Month, label: "По месяцам" }
    ];
    const [period, setPeriod] = useState<TimeSort>(TimeSort.Week);
    const isMobile = useIsMobile();

    const {
        data: rawData,
        error,
        isLoading,
        isFetching
    } = useGetAnalyticsForOfferQuery(
        {
            id: offer.id || "",
            period
        },
        {
            skip: !offer?.id
        }
    );
    const isFetchingData = isLoading || isFetching;

    const [data, setData] = useState<any[]>([]);
    const [minCurrentPrice, setMinCurrentPrice] = useState<number>(0);
    const [maxCurrentPrice, setNewMaxCurrentPrice] = useState<number>(0);
    useEffect(() => {
        if (rawData) {
            try {
                const newData: ChartData[] = rawData
                    .map((item) => {
                        const parsedItem = ApiChartResponseSchema.parse(item);

                        const date = DateTime.fromISO(parsedItem.date).setLocale("ru");
                        const label = date.toFormat("LLL, d").replace(".,", ",");
                        return {
                            name: label,
                            price: parsedItem.avgPrice / 1000,
                            currentPrice: offer.price / 1000
                        };
                    })
                    .slice(-maxSlices);

                const minCurrentPrice = newData.reduce((min, data) => {
                    return data.currentPrice < min ? data.currentPrice : min;
                }, newData[0]?.currentPrice);

                const maxCurrentPrice = newData.reduce((max, data) => {
                    return data.currentPrice > max ? data.currentPrice : max;
                }, newData[0]?.currentPrice);

                const newMinCurrentPrice = minCurrentPrice - 30;
                const newMaxCurrentPrice = maxCurrentPrice + 30;
                setMinCurrentPrice(newMinCurrentPrice);
                setNewMaxCurrentPrice(newMaxCurrentPrice);
                setData(newData);
            } catch {
                // eslint-disable-next-line no-empty
            }
        }
    }, [rawData, error, offer]);

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={classes.Tooltip}>
                    <div>{capitalize(label)}</div>
                    <div>{numberWithSpaces(payload[1].value)} 000 ₽</div>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            {!error && data.length > 0 && (
                <div className={classes.CardChart}>
                    <>
                        <div className={classes.Header}>
                            <h3>Как менялись цены в объявлениях</h3>
                            {isMobile && (
                                <p>
                                    Цены в объявлениях не всегда совпадают с фактической ценой
                                    продажи.
                                </p>
                            )}
                            <SelectInput
                                className={classes.Select2}
                                width={170}
                                instanceId={"sort2324-react-selectasdfsdf3"}
                                value={options.find((item) => item.value === period)}
                                onChange={(val) => {
                                    setPeriod(val?.value as TimeSort);
                                }}
                                options={options}
                            />
                        </div>

                        {!isMobile && (
                            <p>
                                Цены в объявлениях не всегда совпадают с фактической ценой продажи.
                            </p>
                        )}
                        {isFetchingData && <div className="custom-loader">loading</div>}
                        {!isFetchingData && (
                            <div
                                style={{
                                    width: isMobile ? "calc(100% + 50px)" : "100%",
                                    position: "relative",
                                    right: isMobile ? 45 : 0
                                }}
                            >
                                <ResponsiveContainer
                                    style={{
                                        opacity: isFetchingData ? 0.5 : 1
                                    }}
                                    width={"100%"}
                                    aspect={4.0 / 1.8}
                                >
                                    <LineChart height={300} data={data}>
                                        <CartesianGrid
                                            stroke="rgba(181, 188, 203, 0.56)"
                                            horizontal={false}
                                        />
                                        <XAxis
                                            dataKey="name"
                                            tick={{
                                                fill: "#747F94",
                                                fontSize: 11
                                            }}
                                            padding={{ left: 0, right: 0 }}
                                        />

                                        <YAxis
                                            domain={[minCurrentPrice, maxCurrentPrice]}
                                            dataKey="price"
                                            tickCount={6}
                                            padding={{ top: 0, bottom: 0 }}
                                            label={
                                                isMobile
                                                    ? undefined
                                                    : {
                                                          value: "Цена, ₽",
                                                          angle: -90,
                                                          position: "insideLeft",
                                                          class: classes.yLabel
                                                      }
                                            }
                                            tick={(e) => {
                                                const {
                                                    payload: { value }
                                                } = e;
                                                e["fill"] = "#747F94";
                                                e["fontSize"] = "11";
                                                let newVal = `${value} к`;
                                                return <Text {...e}>{newVal}</Text>;
                                            }}
                                        />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Line
                                            type="monotone"
                                            dataKey="currentPrice"
                                            stroke="#51C463"
                                            strokeWidth={2}
                                            dot={false}
                                            activeDot={false}
                                        />

                                        <Line
                                            type="monotone"
                                            dataKey="price"
                                            stroke="#3B85F7"
                                            strokeWidth={3}
                                            dot={false}
                                            activeDot={(args: any) => {
                                                return (
                                                    <>
                                                        <circle
                                                            cx={args.cx}
                                                            cy={args.cy}
                                                            r="15"
                                                            fill="#3B85F7"
                                                            fill-opacity="0.2"
                                                        />
                                                        <circle
                                                            cx={args.cx}
                                                            cy={args.cy}
                                                            r="7.5"
                                                            fill="#3B85F7"
                                                            stroke="white"
                                                            strokeWidth="3"
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        )}

                        <div className={classes.Legend}>
                            <div>
                                <div className={classes.BlueLine}></div>Динамика цен
                            </div>
                            <div>
                                <div className={classes.GreenLine}></div>Стоимость объявления
                            </div>
                        </div>
                    </>
                </div>
            )}
        </>
    );
};
export default CardChart;