import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const YaLoginIcon: FC<IconProps> = ({ className, onClick }) => (
    <svg
        className={className}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
    >
        <rect x="0.25" y="0.25" width="59.5" height="59.5" rx="5.75" fill="white" />
        <rect
            x="0.25"
            y="0.25"
            width="59.5"
            height="59.5"
            rx="5.75"
            stroke="#E3E6EB"
            strokeWidth="0.5"
        />
        <path
            d="M14 30C14 21.1632 21.1616 14 30 14C38.8352 14 46 21.1632 46 30C46 38.8368 38.8352 46 30 46C21.1616 46 14 38.8368 14 30Z"
            fill="#FC3F1D"
        />
        <path
            d="M31.9967 23.2391H30.5552C27.9126 23.2391 26.5226 24.5775 26.5226 26.5509C26.5226 28.7817 27.4836 29.8269 29.457 31.167L31.0872 32.2652L26.4025 39.2649H22.9019L27.1061 33.0031C24.6881 31.2699 23.3309 29.5867 23.3309 26.7397C23.3309 23.1704 25.8191 20.7337 30.5381 20.7337H35.2227V39.2478H31.9967V23.2391Z"
            fill="white"
        />
    </svg>
);