import React from "react";
import SvgInterface from "../../../lib/interfaces/SvgInterface";

export default React.memo(function PlaceholderPic({ width }: SvgInterface) {
    return (
        <svg
            width={width || "273"}
            height="215"
            viewBox="0 0 273 215"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 12C0 5.37259 5.37258 0 12 0H261C267.627 0 273 5.37258 273 12V215H0V12Z"
                fill="#F8F8F8"
            />
            <path
                d="M145.414 98.2892H150.74V98.2887C152.694 98.2887 154.277 99.8722 154.277 101.826V115.466C154.277 116.834 153.493 118.007 152.356 118.596L143.552 109.792C143.656 109.322 143.715 108.835 143.715 108.334C143.715 104.622 140.706 101.613 136.993 101.613C136.492 101.613 136.006 101.672 135.536 101.776L130.133 96.3728L130.478 95.7225C130.679 95.3426 131.075 95.1055 131.505 95.1055L142.482 95.106C142.913 95.106 143.307 95.3431 143.509 95.723L144.617 97.8098C144.773 98.1046 145.08 98.2892 145.414 98.2892Z"
                fill="#C8CEDA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M136.993 78C145.008 78 152.543 81.121 158.211 86.7888V86.7893C169.91 98.4881 169.91 117.525 158.211 129.225C152.362 135.075 144.677 138 136.993 138C129.309 138 121.624 135.075 115.774 129.225C104.075 117.525 104.075 98.4885 115.774 86.7893C121.442 81.121 128.977 78 136.993 78ZM116.733 92.5188C109.083 102.511 109.82 116.907 118.956 126.043L118.956 126.043C128.091 135.178 142.488 135.915 152.48 128.266L143.218 119.004H123.246C121.292 119.004 119.709 117.421 119.709 115.467V101.827C119.709 100.104 120.941 98.6731 122.571 98.3575L116.733 92.5188ZM130.573 106.359C130.38 106.984 130.271 107.646 130.271 108.335C130.271 112.047 133.281 115.057 136.993 115.057C137.682 115.057 138.344 114.948 138.969 114.755L130.573 106.359ZM118.955 89.9691L155.029 126.043H155.03C164.974 116.097 164.974 99.9146 155.029 89.9691C150.211 85.1513 143.806 82.4979 136.992 82.4979C130.178 82.4979 123.773 85.1514 118.955 89.9691Z"
                fill="#C8CEDA"
            />
        </svg>
    );
});