import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { clearErrors, confirmSmsCode, fastLogin } from "@app/store/authSlice";
import { AppState } from "@app/store/store";
import { PrimaryButton } from "../../../UI/Buttons/PrimaryButton/PrimaryButton";
import { formatToPhone } from "../../../../utils/utils";
import CodeInput from "../CodeInput/CodeInput";
import { TIMER_DURATION } from "../../Catalog/catalogUtils";
import { EnterCodeFormProps } from "./types";
import classes from "./EnterCodeForm.module.scss";

export const REG_TEXT_FONT_SIZE = 10;

const EnterCodeForm = ({ onClose }: EnterCodeFormProps) => {
    const dispatch = useDispatch<ThunkDispatch<AppState, any, any>>();
    const isLoggingIn = useSelector((state: AppState) => state.auth.isLoggingIn);
    const phoneMemory = useSelector((state: AppState) => state.auth.phoneMemory);

    const [codeError, setCodeError] = useState("");

    const [isCodeSent, setIsCodeSent] = useState(true);
    const [canSendAgain, setCanSendAgain] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [currentCode, setCurrentCode] = useState("");
    const startTimer = () => {
        setTimeLeft(TIMER_DURATION);
        const timer = setInterval(() => {
            setTimeLeft((timeLeft) => timeLeft - 1);
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            setCanSendAgain(true);
        }, 1000 * TIMER_DURATION);
    };

    useEffect(() => {
        dispatch(clearErrors());
        startTimer();
    }, []);

    const { getValues } = useForm();

    const disptach = useDispatch<any>();

    const sendCode = () => {
        setIsCodeSent(true);
        setCanSendAgain(false);
        setCodeError("");
        const phone = getValues("RegPhone");
        disptach(
            fastLogin({
                phone: phone
            })
        );
        startTimer();
    };

    const [isEntering, _setIsEntering] = useState(false);
    useEffect(() => {
        if (currentCode) {
            const codeEntered = async () => {
                const res = await dispatch(
                    confirmSmsCode({
                        phone: phoneMemory,
                        code: currentCode
                    })
                );

                if (res.payload?.status >= 200 && res.payload?.status < 300) {
                    onClose();
                } else {
                    setCodeError("Неверный код подтверждения");
                }
            };
            codeEntered();
        }
    }, [currentCode, phoneMemory]);

    return (
        <div className={classes.EnterCodeForm}>
            <div onClick={onClose} className={classes.CloseBtn}>
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.692 1.79511C14.1027 1.38445 14.1027 0.718653 13.692 0.307993C13.2814 -0.102656 12.6155 -0.102661 12.2049 0.307993L7 5.51289L1.79511 0.307993C1.38445 -0.102667 0.718647 -0.102661 0.307993 0.307993C-0.102661 0.718647 -0.102667 1.38445 0.307993 1.79511L5.51289 7L0.307993 12.2049C-0.102661 12.6155 -0.102667 13.2813 0.307993 13.692C0.718653 14.1027 1.38445 14.1027 1.79511 13.692L7 8.48711L12.2049 13.692C12.6156 14.1027 13.2814 14.1027 13.692 13.692C14.1027 13.2814 14.1027 12.6156 13.692 12.2049L8.48711 7L13.692 1.79511Z"
                        fill="#F86A6A"
                    />
                </svg>
            </div>
            <h3>Введите код</h3>
            <form>
                {isCodeSent && (
                    <>
                        <p className={classes.CodeSentP}>
                            Мы отправили код на телефон:
                            <br />
                            <b>{formatToPhone(phoneMemory || "")}</b>
                        </p>

                        <label className={classes.CodeSentLabel}>Введите код:</label>
                        <CodeInput
                            onChange={(code) => {
                                setCurrentCode(code);
                            }}
                        />

                        {codeError && <span className={classes.CodeError}>{codeError}</span>}
                    </>
                )}
            </form>

            {!isCodeSent && (
                <PrimaryButton
                    isLoading={isLoggingIn}
                    onClick={(e) => {
                        e.preventDefault();
                        sendCode();
                    }}
                    style={{
                        marginTop: 22,
                        marginBottom: 18,
                        height: 56,
                        fontSize: 18
                    }}
                    content="Получить код"
                />
            )}

            {isCodeSent && (
                <PrimaryButton
                    isLoading={isEntering}
                    onClick={async (e) => {
                        e.preventDefault();
                        await dispatch(
                            confirmSmsCode({
                                phone: phoneMemory,
                                code: currentCode
                            })
                        );
                    }}
                    style={{
                        marginTop: 22,
                        marginBottom: 18,
                        height: 56,
                        fontSize: 18
                    }}
                    content="Далее"
                />
            )}

            {isCodeSent && !canSendAgain && (
                <p className={classes.SendCodeAgain}>Отправить код повторно: {timeLeft} сек.</p>
            )}
            {canSendAgain && (
                <p className={classes.SendCodeAgain}>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            sendCode();
                        }}
                        href="#"
                    >
                        Отправить код
                    </a>
                </p>
            )}
        </div>
    );
};
export default EnterCodeForm;