import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const KolesaKzIcon: FC<IconProps> = ({ className, color = "#fff" }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 162 28">
        <path
            fill={color}
            d="M12.193 6.8686 6.79 14.7075H4.877V0H0v27.3937h4.877v-8.8082h1.906l5.41 8.8093h5.14v-.6063l-6.798-10.3116 6.723-9.0429v-.5654h-5.065Z"
        />
        <path
            fill={color}
            fillRule="evenodd"
            d="M28.791 28c-1.926 0-3.627-.4717-5.102-1.414-1.4587-.9236-2.6632-2.2482-3.49-3.8381-.85-1.6165-1.275-3.4613-1.275-5.5354 0-2.0473.412-3.8791 1.238-5.4956.85-1.6433 2.013-2.9227 3.489-3.8381 1.5-.9423 3.214-1.414 5.14-1.414 1.9 0 3.59.4717 5.065 1.414 1.4857.8992 2.7076 2.2289 3.527 3.8381.85 1.6165 1.275 3.4483 1.275 5.4956 0 2.0741-.425 3.9189-1.275 5.5354-.8337 1.5977-2.0522 2.9237-3.527 3.8381C32.38 27.5283 30.693 28 28.791 28Zm0-4.6868c1.026 0 1.926-.2563 2.701-.7678.8-.5385 1.414-1.2654 1.839-2.1818.425-.9154.637-1.9665.637-3.1511s-.212-2.2357-.637-3.1522c-.4052-.8921-1.0446-1.6365-1.839-2.1409-.775-.5115-1.675-.7678-2.701-.7678-1.05 0-1.963.2563-2.739.7678-.7771.5144-1.4017 1.2572-1.8 2.1409-.426.9154-.639 1.9665-.639 3.1522 0 1.1846.213 2.2357.638 3.1511.4044.8905 1.0272 1.645 1.801 2.1818.776.5115 1.689.7678 2.739.7678Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            d="M44.082 26.3437c.65.7 1.45 1.05 2.401 1.05h5.103v-4.4445h-3.564V0H39.58v3.8382h3.527V23.758c0 1.0231.325 1.8857.975 2.5857Z"
        />
        <path
            fill={color}
            fillRule="evenodd"
            d="M63.925 28c-1.901 0-3.614-.4717-5.14-1.414-1.48-.9206-2.7091-2.2443-3.564-3.8382-.85-1.6164-1.276-3.4612-1.276-5.5353 0-2.1011.438-3.9599 1.313-5.5764.876-1.6153 2.039-2.8818 3.49-3.7972 1.4312-.9103 3.0649-1.3852 4.727-1.3741 1.45 0 2.813.323 4.09.9692 1.2662.6395 2.3913 1.5625 3.3 2.7074.9522 1.1606 1.6682 2.5231 2.102 3.9997l-13.43 7.2832c.513.7118 1.113 1.2482 1.8 1.6057.824.4308 1.65.6461 2.475.6461.9 0 1.776-.2283 2.627-.686.8329-.467 1.5231-1.1826 1.988-2.0612h4.727c-.675 2.1819-1.838 3.906-3.489 5.1725C68.04 27.3668 66.125 28 63.925 28ZM58.41 17.1715c0 .196.004.3877.013.5751l9.069-4.9732a3.437 3.437 0 0 0-.228-.2886c-.5-.5923-1.05-.9962-1.65-1.2127a4.869 4.869 0 0 0-1.84-.3629c-.975 0-1.863.2423-2.663.7269-.8.4847-1.45 1.1987-1.95 2.142-.501.9154-.751 2.0462-.751 3.3934Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            d="M86.694 22.4646c-.225.3231-.588.4846-1.088.4846h-8.554v4.4445h9.38c1.65 0 2.951-.5654 3.901-1.6972.95-1.1308 1.426-2.6525 1.426-4.5662 0-1.9384-.488-3.4871-1.463-4.6458-.95-1.1846-2.239-1.777-3.864-1.777h-4.428c-.475 0-.838-.1356-1.088-.4049-.225-.2961-.337-.7129-.337-1.2524 0-.5923.125-1.0231.375-1.2923.25-.2962.6-.4448 1.05-.4448h8.292V6.8686h-8.892c-1.7 0-3.051.5525-4.052 1.6563-.975 1.077-1.463 2.5459-1.463 4.4046 0 1.8847.488 3.3934 1.463 4.5253 1 1.1038 2.351 1.6563 4.052 1.6563h4.202c.5 0 .863.1615 1.088.4846.25.2962.375.7678.375 1.414 0 .6462-.125 1.1308-.375 1.4549Z"
        />
        <path
            fill={color}
            fillRule="evenodd"
            d="M103.659 28c-1.609.0133-3.186-.478-4.54-1.414-1.375-.9692-2.475-2.2626-3.3-3.8791-.826-1.6154-1.239-3.4472-1.239-5.4944 0-2.0473.413-3.8791 1.238-5.4956.826-1.6434 1.926-2.9228 3.302-3.8381 1.369-.933 2.957-1.4237 4.577-1.414 1.351 0 2.514.3101 3.489.9293 1.001.5924 1.763 1.3871 2.289 2.3833h.45V6.8697h4.727v20.524h-4.727V24.444h-.45c-.501 1.0231-1.251 1.8728-2.251 2.5458-.976.6731-2.164 1.0102-3.565 1.0102Zm5.853-13.1309c.273.7215.411 1.4958.413 2.3229a7.1123 7.1123 0 0 1-.413 2.4446c-.261.7034-.643 1.3475-1.125 1.8986-.476.5385-1.038.9563-1.689 1.2525-.625.2961-1.325.4448-2.101.4448-1 0-1.901-.2563-2.701-.7679-.793-.531-1.451-1.2669-1.913-2.1409-.45-.9154-.676-1.9535-.676-3.1123 0-1.1577.225-2.1808.676-3.0692.456-.8686 1.116-1.5933 1.913-2.1022.8-.5385 1.701-.8077 2.701-.8077.776 0 1.476.1486 2.101.4448.638.2867 1.213.7132 1.689 1.2524.475.5385.85 1.1847 1.125 1.9385v.0011Z"
            clipRule="evenodd"
        />
        <path
            fill={color}
            d="m139.11 6.8686-5.404 7.8389h-1.912V0h-4.878v27.3937h4.878v-8.8082h1.906l5.41 8.8093h5.14v-.6063l-6.799-10.3116 6.724-9.0429v-.5654h-5.065Zm17.329 4.4445-9.105 11.6361v4.4445h14.407v-4.4445h-9.166l9.166-11.6361V6.8686h-14.407v4.4445h9.105Zm-37.91 11.6361v4.4445h4.653v-4.4445h-4.653Z"
        />
    </svg>
);