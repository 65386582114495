import React, { useEffect, useRef, useState } from "react";
import { CodeInputProps } from "./types";
import { CODE_INPUT_PATTERN, MAX_LENGTH_INPUT, NUM_INPUTS } from "./constants";
import classes from "./CodeInput.module.scss";

const CodeInput = ({ onChange }: CodeInputProps) => {
    const [values, setValues] = useState<string[]>(Array(NUM_INPUTS).fill(""));
    const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(NUM_INPUTS).fill(null));

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = event.target.value.replace(/\D/g, "");
        if (!enteredValue) return;

        const newValues = [...values];
        newValues[index] = enteredValue.charAt(0);
        const charsToInsert = enteredValue.slice(1).split("");
        newValues.splice(index + 1, charsToInsert.length, ...charsToInsert);
        setValues(newValues);

        const nextIndex = index + enteredValue.length;
        if (nextIndex >= values.length) {
            inputRefs.current[values.length - 1]?.focus();
            return;
        }
        inputRefs.current[nextIndex]?.focus();
    };

    const handleBackspace = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Backspace") return;

        const newValues = [...values];
        if (values[index] !== "") {
            newValues[index] = "";
            setValues(newValues);
            return;
        }

        if (index > 0) {
            newValues[index - 1] = "";
            setValues(newValues);
            inputRefs.current[index - 1]?.focus();
        }
    };

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    useEffect(() => {
        const code = values.join("");
        const isComplete = code.length === NUM_INPUTS;
        onChange(isComplete ? code : "");
    }, [values, onChange]);

    return (
        <div className={classes.CodeInput}>
            {values.map((value, index) => (
                <React.Fragment key={index}>
                    <div className={classes.CodeInput__inner}>
                        <input
                            className={classes.CodeInput__input}
                            pattern={CODE_INPUT_PATTERN}
                            inputMode="numeric"
                            value={value}
                            onChange={(e) => handleInputChange(index, e)}
                            onKeyDown={(e) => handleBackspace(index, e)}
                            type="text"
                            maxLength={MAX_LENGTH_INPUT}
                            placeholder="0"
                            ref={(el) => (inputRefs.current[index] = el)}
                        />
                    </div>
                    {index === 2 && <div className={classes.CodeInput__divider} />}
                </React.Fragment>
            ))}
        </div>
    );
};

export default CodeInput;