import { motion } from "framer-motion";
import { PrimaryButtonProps } from "./types";
import classes from "./PrimaryButton.module.scss";

export const PrimaryButton = (props: PrimaryButtonProps) => {
    return (
        <motion.button
            disabled={props.isDisabled}
            onClick={props.onClick}
            style={{
                ...props.style
            }}
            className={`${classes.PrimaryButton} ${props.type ? classes[props.type] : null} ${props.className} ${props.isLoading ? classes.Loading : null}`}
        >
            {props.isLoading && <div className={classes.Loader} />}

            {!!props.icon && props.icon}
            {props.content}
        </motion.button>
    );
};