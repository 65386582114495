import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

export default React.memo(function GalleryPrevIcon({ ...other }: SvgInterface) {
    return (
        <svg
            className={other.className}
            onClick={other.onClick}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="32"
                y="32"
                width="32"
                height="32"
                rx="16"
                transform="rotate(180 32 32)"
                fill="white"
                fillOpacity="0.51"
            />
            <path
                d="M17.5657 10.5657C17.2533 10.2533 16.7467 10.2533 16.4343 10.5657L11.9088 15.0912C11.5964 15.4036 11.5964 15.9101 11.9088 16.2225L16.4343 20.748C16.7467 21.0604 17.2533 21.0604 17.5657 20.748C17.8781 20.4356 17.8781 19.9291 17.5657 19.6167L13.6059 15.6569L17.5657 11.6971C17.8781 11.3846 17.8781 10.8781 17.5657 10.5657Z"
                fill="#343B47"
            />
        </svg>
    );
});