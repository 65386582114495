import { useCallback, useEffect } from "react";

import { CallbackFunction } from "./types";

export const useEscapeKey = (callback: CallbackFunction): void => {
    const handleKeyPress = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                callback();
            }
        },
        [callback]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);
};