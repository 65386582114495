export const getCurrencyCode = (sourceId: number): string => {
    switch (sourceId) {
        case 6:
        case 7:
        case 8:
            return "KZT";
        case 9:
            return "EUR";
        case 10:
            return "BYN";
        default:
            return "RUB";
    }
};