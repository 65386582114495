import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const AvitoIcon: FC<IconProps> = ({ className, color = "#fff" }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 103 28">
        <g clipPath="url(#a)">
            <path
                fill={color}
                d="m39.12.579-8.737 22.813h4.693l1.795-4.766h9.271l1.803 4.766h4.659L43.92.58H39.12Zm-.638 13.86 3.052-8.027 3.04 8.027h-6.092Zm19.73 3.07L54.425 7.366h-4.478L56.05 23.39h4.438l5.994-16.025h-4.477l-3.793 10.143ZM72.114 7.366h-4.26V23.39h4.26V7.366ZM69.98 6.21a3.106 3.106 0 1 0 0-6.21 3.106 3.106 0 0 0 0 6.21ZM80.557 3.09h-4.249v4.249h-2.492V11.2h2.492v6.81c0 3.863 2.128 5.524 5.126 5.524a7.339 7.339 0 0 0 2.947-.576v-3.97a4.746 4.746 0 0 1-1.587.29c-1.302 0-2.24-.507-2.24-2.24V11.2h3.827V7.377h-3.824V3.09Zm12.781 3.986a8.305 8.305 0 0 0-.007 16.61 8.305 8.305 0 0 0 .007-16.61Zm0 12.36a4.044 4.044 0 1 1 4.04-4.043 4.035 4.035 0 0 1-4.04 4.028v.016Z"
            />
            <path
                fill="#965EEB"
                d="M10.342 7.98a3.103 3.103 0 1 0 0-6.207 3.103 3.103 0 0 0 0 6.207Z"
            />
            <path
                fill="#0AF"
                d="M21.214 13.505a6.753 6.753 0 1 0 0-13.505 6.753 6.753 0 0 0 0 13.505Z"
            />
            <path
                fill="#04E061"
                d="M8.76 25.667a8.307 8.307 0 1 0 0-16.613 8.307 8.307 0 0 0 0 16.613Z"
            />
            <path
                fill="#FF4053"
                d="M23.157 24.621a5.017 5.017 0 1 0 0-10.033 5.017 5.017 0 0 0 0 10.033Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M.453 0h101.733v28H.453z" />
            </clipPath>
        </defs>
    </svg>
);