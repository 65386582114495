import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const VkLoginIcon: FC<IconProps> = ({ className, onClick }) => (
    <svg
        className={className}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
    >
        <rect x="0.25" y="0.25" width="59.5" height="59.5" rx="5.75" fill="white" />
        <rect
            x="0.25"
            y="0.25"
            width="59.5"
            height="59.5"
            rx="5.75"
            stroke="#E3E6EB"
            strokeWidth="0.5"
        />
        <path
            d="M14 30C14 21.1632 21.1616 14 30 14C38.8352 14 46 21.1632 46 30C46 38.8368 38.8352 46 30 46C21.1616 46 14 38.8368 14 30Z"
            fill="#0077FF"
        />
        <path
            d="M30.9984 36.8743C23.8848 36.8743 19.8288 32.0071 19.6624 23.8951H23.24C23.3544 29.8439 25.9752 32.3607 28.0552 32.8807V23.8951H31.4144V29.0223C33.4632 28.8039 35.6264 26.4639 36.3544 23.8847H39.7032C39.4301 25.2198 38.8847 26.4843 38.1009 27.5991C37.3171 28.7139 36.3119 29.6551 35.148 30.3639C36.4468 31.0104 37.5939 31.9249 38.5136 33.0471C39.4332 34.1693 40.1045 35.4737 40.4832 36.8743H36.7912C36.0008 34.4095 34.0248 32.4959 31.4144 32.2359V36.8743H31.0088H30.9984Z"
            fill="white"
        />
    </svg>
);