import React from "react";

export default React.memo(function AutoIcon() {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.50503 5.49497C7.77839 5.22161 8.22161 5.22161 8.49497 5.49497L12.4548 9.45477C12.7281 9.72814 12.7281 10.1714 12.4548 10.4447L8.49498 14.4045C8.22161 14.6779 7.77839 14.6779 7.50503 14.4045C7.23166 14.1312 7.23166 13.6879 7.50503 13.4146L10.9698 9.94975L7.50503 6.48492C7.23166 6.21156 7.23166 5.76834 7.50503 5.49497Z"
                fill="#747F94"
            />
        </svg>
    );
});