import React from "react";
import { LocationSnippetProps } from "./types";
import classes from "./LocationSnippet.module.scss";

const LocationSnippet = ({ style, text }: LocationSnippetProps) => {
    return (
        <div style={style} className={classes.LocationSnippet}>
            <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="4" cy="4" r="4" fill="#3B85F7" />
            </svg>
            {text && <>{text}</>}
            {!text && <>Домодедовская, Орехово, Москва</>}
        </div>
    );
};
export default LocationSnippet;