import { COOKIE_CONSENT_KEY } from "./constants";

export const getConsent = () => {
    if (typeof window !== "undefined" && window.localStorage) {
        const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
        return consent ? JSON.parse(consent) : null;
    }
    return null;
};

export const setConsent = (value: boolean) => {
    if (typeof window !== "undefined" && window.localStorage) {
        localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify({ isApproved: value }));
    }
};