import React from "react";
import { useDispatch } from "react-redux";
import { setIsSoonVisible } from "@app/store/layoutSlice";
import { PrimaryButton } from "../../UI/Buttons/PrimaryButton/PrimaryButton";
import classes from "./AvailableSoon.module.scss";

const AvailableSoon = () => {
    const dispatch = useDispatch();
    return (
        <div className={classes.AvailableSoon}>
            <label>Скоро</label>
            <p>Мы продолжаем развивать наш сервис и уже совсем скоро мы добавим новые категории</p>

            <ul>
                <li>
                    <svg
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="40" cy="40" r="40" fill="#06CE86" />
                        <path
                            d="M43.5143 34.0525C43.8371 33.7297 44.2745 33.5485 44.7308 33.5485C45.1871 33.5485 45.6245 33.7297 45.9473 34.0525C46.2698 34.375 46.4511 34.8128 46.4511 35.2688C46.4511 37.4201 45.3035 39.408 43.4404 40.4836C41.5774 41.5591 39.2823 41.5591 37.4192 40.4836C35.5561 39.4081 34.4086 37.4202 34.4086 35.2688C34.4086 34.6543 34.7365 34.0865 35.2687 33.7789C35.8009 33.4717 36.4568 33.4717 36.989 33.7789C37.5212 34.0864 37.8491 34.6543 37.8491 35.2688C37.8491 36.1907 38.3411 37.0428 39.1395 37.5039C39.9381 37.9647 40.9216 37.9647 41.7202 37.5039C42.5186 37.0428 43.0106 36.1907 43.0106 35.2688C43.0106 34.8127 43.1918 34.375 43.5143 34.0525Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M52.7995 31.729L54.1631 46.0559H54.1628C54.1835 46.2647 54.1934 46.4741 54.1928 46.6838C54.1916 48.447 53.4908 50.1376 52.2439 51.3844C50.9974 52.6313 49.3068 53.3321 47.5437 53.3333H33.3159C31.5527 53.3321 29.8621 52.6312 28.6156 51.3844C27.3687 50.1376 26.6679 48.447 26.6667 46.6838C26.6661 46.4741 26.676 46.2647 26.6967 46.0559L28.0603 31.729C28.1917 30.3442 28.8349 29.058 29.8642 28.1217C30.8933 27.1856 32.2344 26.6669 33.6255 26.6666H47.2343C48.6255 26.6669 49.9665 27.1856 50.9956 28.1217C52.0249 29.058 52.6681 30.3442 52.7995 31.729ZM33.3161 49.8924H47.5442C48.395 49.8924 49.2111 49.5544 49.8129 48.9525C50.4147 48.351 50.7525 47.535 50.7525 46.6838V46.3784L49.385 32.0562C49.3343 31.5215 49.0856 31.0253 48.6878 30.6647C48.2899 30.3041 47.7712 30.1055 47.2345 30.1079H33.6258C33.0891 30.1055 32.5703 30.3041 32.1725 30.6647C31.7747 31.0253 31.526 31.5215 31.4753 32.0562H31.4756L30.1078 46.3784V46.6838C30.1078 47.535 30.4456 48.351 31.0474 48.9525C31.6492 49.5543 32.4652 49.8924 33.3161 49.8924Z"
                            fill="white"
                        />
                    </svg>

                    <span>Маркетплейсы</span>
                </li>

                <li>
                    <svg
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="40" cy="40" r="40" fill="#F74282" />
                        <path
                            d="M27.8871 52.1129C27.1056 51.3316 26.6667 50.2717 26.6667 49.1666L26.6667 48.654C26.6638 47.4284 26.9149 46.2153 27.4041 45.0916C28.0013 43.7186 28.9323 42.5168 30.1126 41.5958C30.8676 41.0055 31.6658 40.4719 32.4999 39.9998C31.2557 38.3408 30.6747 36.2773 30.8706 34.2128C31.0665 32.1481 32.0251 30.2308 33.5594 28.8354C35.0934 27.4398 37.0927 26.6666 39.1668 26.6666H39.4917C41.5794 26.7556 43.5581 27.6217 45.0398 29.095C46.5215 30.5683 47.3989 32.542 47.5001 34.6291V35C47.5001 35.5955 47.1824 36.1457 46.6668 36.4433C46.1509 36.7409 45.5156 36.7409 45 36.4433C44.4844 36.1457 44.1667 35.5955 44.1667 35V34.7707C44.1045 33.5206 43.5782 32.3386 42.6902 31.4562C41.8023 30.5738 40.6174 30.0544 39.3667 30.0001H39.1667C37.7347 29.9989 36.371 30.6116 35.4212 31.6832C34.4714 32.7548 34.0264 34.1819 34.1993 35.6034C34.3722 37.025 35.1459 38.3041 36.3251 39.1168C36.6558 39.3481 36.8912 39.6917 36.9877 40.0837C37.0842 40.4758 37.0351 40.8894 36.8497 41.248C36.6643 41.6067 36.3547 41.8857 35.9792 42.0334C34.6079 42.5752 33.3233 43.3151 32.1665 44.2292C31.4254 44.8084 30.8398 45.5633 30.4625 46.425C30.1559 47.1281 29.9984 47.8872 30.0001 48.6543V49.1667C30.0001 49.6271 30.373 50 30.8334 50H38.3334C38.9286 50 39.4788 50.3176 39.7767 50.8335C40.0743 51.3491 40.0743 51.9844 39.7767 52.5C39.4789 53.0156 38.9286 53.3333 38.3334 53.3333H30.8334C29.7283 53.3333 28.6683 52.8945 27.8871 52.1129Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M42.1807 41.7151C43.2836 40.3718 44.9284 39.5902 46.6667 39.5833L46.6668 39.5831C48.0954 39.5823 49.4748 40.1055 50.5432 41.0535C51.6116 42.0019 52.2951 43.3093 52.4637 44.7279C52.6325 46.1466 52.2748 47.5777 51.4584 48.7501L52.9668 50.6376C53.317 51.1038 53.3981 51.7191 53.1807 52.26C52.9633 52.8011 52.4788 53.1892 51.9036 53.2833C51.3281 53.3775 50.7454 53.1642 50.3667 52.7209L48.8542 50.8335C47.2402 51.4785 45.4225 51.3704 43.8964 50.5379C42.3702 49.7058 41.2948 48.2363 40.9632 46.5299C40.6312 44.8238 41.0777 43.0585 42.1807 41.7151ZM44.8987 43.6491C44.4299 44.1179 44.1666 44.7538 44.1666 45.4168C44.1666 46.0797 44.4299 46.7157 44.8987 47.1845C45.3675 47.6533 46.0035 47.9166 46.6667 47.9166C47.3297 47.9166 47.9656 47.6533 48.4344 47.1845C48.9032 46.7157 49.1666 46.0797 49.1666 45.4168C49.1666 44.7538 48.9033 44.1179 48.4344 43.6491C47.9656 43.18 47.3297 42.9166 46.6667 42.9166C46.0035 42.9166 45.3675 43.18 44.8987 43.6491Z"
                            fill="white"
                        />
                    </svg>

                    <span>Работа</span>
                </li>
                <li>
                    <svg
                        width="80"
                        height="80"
                        viewBox="0 0 80 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="40" cy="40" r="40" fill="#CE55E1" />
                        <path
                            d="M45.0687 47.4221H35.9098C35.3151 47.4221 34.7654 47.1047 34.4677 46.5896C34.1704 46.0744 34.1704 45.4396 34.4677 44.9244C34.7653 44.409 35.3151 44.0916 35.9098 44.0916H45.0687C45.6634 44.0916 46.2131 44.409 46.5107 44.9244C46.8081 45.4396 46.8081 46.0744 46.5107 46.5896C46.2132 47.1047 45.6634 47.4221 45.0687 47.4221Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M48.8155 53.6668H32.1631C31.0589 53.6668 29.9999 53.2283 29.2193 52.4474C28.4384 51.6669 27.9999 50.6078 27.9999 49.5037V37.4306C27.9985 35.8346 28.7596 34.3338 30.0481 33.3924H30.0484L37.4753 27.9802C38.3496 27.3434 39.4035 27.0001 40.4852 27.0001C41.5669 27.0001 42.6207 27.3434 43.4952 27.9802L50.922 33.3924C52.2137 34.3321 52.978 35.8332 52.9786 37.4306V49.5037C52.9786 50.6078 52.5401 51.6669 51.7593 52.4474C50.9787 53.2283 49.9196 53.6668 48.8155 53.6668ZM32.0172 36.0858C31.5866 36.3985 31.3313 36.8983 31.3304 37.4306L31.3304 49.5037C31.3304 49.9637 31.703 50.3362 32.163 50.3362H48.8154C49.0362 50.3362 49.2481 50.2485 49.4041 50.0926C49.5603 49.9364 49.648 49.7247 49.648 49.5037V37.4306C49.6451 36.8968 49.3867 36.3968 48.9528 36.0858L41.5259 30.6736C41.2228 30.4555 40.8586 30.3382 40.4852 30.3382C40.1114 30.3382 39.7476 30.4555 39.4444 30.6736H39.4441L32.0172 36.0858Z"
                            fill="white"
                        />
                    </svg>

                    <span>Недвижимость</span>
                </li>
            </ul>

            <PrimaryButton
                style={{
                    width: "300px",
                    height: "56px",
                    fontSize: 18,
                    marginTop: 40
                }}
                content="Хорошо"
                onClick={() => {
                    dispatch(setIsSoonVisible(false));
                }}
            />
        </div>
    );
};
export default AvailableSoon;