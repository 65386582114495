export const TITLE_CONTENT_FORM = "Восстановление пароля";

export enum RecoveryForm {
    PASSWORD_LABEL = "Пароль",
    PASSWORD_PLACEHOLDER = "Введите пароль",
    PASSWORD_REQUIRED = 'Заполните поле "Пароль"',
    PASSWORD_REPEAT = "Повторите пароль",
    PASSWORD_CHANGE_SUCCESS = "Пароль успешно сменен. Теперь вы можете войти в свой аккаунт."
}

export enum RecoveryErrors {
    PASSWORD_MISMATCH = "Пароли не совпадают",
    EXPIRED_LINK = "Ссылка на изменение пароля устарела"
}

export enum RecoveryActions {
    BUTTON_SENT_FORM = "Отправить",
    LOGIN_BUTTON_MISMATCH = "Войти в свой аккаунт",
    TRY_AGAIN_BUTTON = "Попробовать снова"
}

export enum ServiceNames {
    VK = "ВКонтакте",
    YANDEX = "Яндекс"
}