import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../../UI/Inputs/TextInput/TextInput";
import { PrimaryButton } from "../../../UI/Buttons/PrimaryButton/PrimaryButton";
import { useSelector, useDispatch } from "react-redux";
import { showEnterByEmail } from "@app/store/layoutSlice";
import { isValidEmail } from "../../../../utils/utils";
import { clearErrors, sendRestorePasswordLink } from "@app/store/authSlice";
import { AppState } from "@app/store/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RestorePassFormProps } from "./types";
import classes from "./RestorePassForm.module.scss";

export const REG_TEXT_FONT_SIZE = 10;

const RestorePassForm = ({ onClose }: RestorePassFormProps) => {
    const dispatch = useDispatch<ThunkDispatch<AppState, any, any>>();
    const [isLoading, setIsLoading] = useState(false);
    const errorMessage = useSelector((state: AppState) => state.auth.errorMessage);
    const textInputStyle = {
        paddingLeft: 10
    };

    const [isSent, setIsSent] = useState(false);

    useEffect(() => {
        dispatch(clearErrors());
    }, []);

    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = () => {
        const submit = handleSubmit(async (data) => {
            setIsLoading(true);
            await dispatch(sendRestorePasswordLink(data.EnterEmail));
            setIsSent(true);
            setIsLoading(false);
        });
        submit();
    };

    const handleEmailValidation = (email: string) => {
        const isValid = isValidEmail(email);
        return isValid ? isValid : "Неверный формат почты";
    };

    return (
        <div
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    event.stopPropagation();
                    event.preventDefault();
                    onSubmit();
                }
            }}
            className={classes.RestorePassForm}
        >
            <div onClick={onClose} className={classes.CloseBtn}>
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.692 1.79511C14.1027 1.38445 14.1027 0.718653 13.692 0.307993C13.2814 -0.102656 12.6155 -0.102661 12.2049 0.307993L7 5.51289L1.79511 0.307993C1.38445 -0.102667 0.718647 -0.102661 0.307993 0.307993C-0.102661 0.718647 -0.102667 1.38445 0.307993 1.79511L5.51289 7L0.307993 12.2049C-0.102661 12.6155 -0.102667 13.2813 0.307993 13.692C0.718653 14.1027 1.38445 14.1027 1.79511 13.692L7 8.48711L12.2049 13.692C12.6156 14.1027 13.2814 14.1027 13.692 13.692C14.1027 13.2814 14.1027 12.6156 13.692 12.2049L8.48711 7L13.692 1.79511Z"
                        fill="#F86A6A"
                    />
                </svg>
            </div>
            <h3>Восстановление пароля</h3>
            {!isSent && (
                <>
                    <p className={classes.Text}>
                        Введите электронную почту, для восстановления пароля
                    </p>
                    <form>
                        <div className={classes.InputCont}>
                            <label>Электронная почта</label>
                            <Controller
                                control={control}
                                name="EnterEmail"
                                rules={{
                                    required: 'Заполните поле "Почта"',
                                    validate: handleEmailValidation
                                }}
                                render={({ field }) => {
                                    return (
                                        <TextInput
                                            value={field.value}
                                            onChange={field.onChange}
                                            style={textInputStyle}
                                            placeholder="Введите электронную почту"
                                        />
                                    );
                                }}
                            />
                            {errors.EnterEmail && (
                                <span className={classes.Error}>
                                    {errors?.EnterEmail?.message &&
                                        errors?.EnterEmail?.message.toString()}
                                </span>
                            )}
                        </div>

                        {errorMessage && (
                            <span className={classes.Error}>
                                {errorMessage.slice(0, 140) + "..."}
                            </span>
                        )}
                    </form>
                    <PrimaryButton
                        isLoading={isLoading}
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                        style={{
                            marginTop: 22,
                            marginBottom: 20,
                            height: 56,
                            fontSize: 18
                        }}
                        content="Отправить"
                    />
                </>
            )}

            {isSent && (
                <>
                    <p className={classes.Text}>
                        На вашу почту была отправлена ссылка для восстановления пароля.
                    </p>
                </>
            )}
            {!isSent && (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(showEnterByEmail());
                    }}
                    className={classes.Link}
                    href="#"
                >
                    Войти
                </a>
            )}
        </div>
    );
};
export default RestorePassForm;