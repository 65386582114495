import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentUserState } from "@app/store/authSlice";
import { showEnterForm } from "@app/store/layoutSlice";
import EnterIcon from "../../components/Svg/EnterIcon";
import FavoriteBtnIcon from "../../components/Svg/FavoriteBtnIcon";
import PlusIcon from "../../components/Svg/PlusIcon";
import ProfileIcon from "../../components/Svg/ProfileIcon";
import Logo from "../../components/UI/Logo/Logo";
import DesktopMenu from "../Menu/DesktopMenu/DesktopMenu";
import Items from "../Menu/MenuItems";
import MobileMenu from "../Menu/MobileMenu/MobileMenu";
import UserMenu from "../UserMenu/UserMenu";
import MenuButton from "./MenuButton/MenuButton";
import { PropertyIcon } from "@shared/ui/Icon/ui/Navigation/PropertyIcon";
import { RouterConfig } from "@shared/lib/routerConfig";
import classes from "./Header.module.scss";

const Header = () => {
    const router = useRouter();
    const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);

    const currentUser = useSelector(currentUserState);

    const dispatch = useDispatch();

    let activeItem = Items.find((el) => {
        return router.pathname.startsWith(el.link);
    });

    activeItem = activeItem
        ? activeItem
        : {
              title: "Главная",
              icon: PropertyIcon,
              link: RouterConfig.MAIN
          };

    return (
        <div className={classes.Header}>
            <div className={classes.Container}>
                <div className={classes.LeftPart}>
                    <Link legacyBehavior href={RouterConfig.MAIN}>
                        <a className={classes.Logo} aria-label="LIKVI.COM">
                            <Logo isHeader={true} />
                        </a>
                    </Link>
                    <DesktopMenu activeItem={activeItem} className={classes.DesktopMenu} />
                    <MobileMenu activeItem={activeItem} className={classes.MobileMenu} />
                </div>

                <div className={classes.RightPart}>
                    <div className={classes.UserMenu}>
                        {!currentUser && (
                            <MenuButton
                                id={classes["EnterButton"]}
                                onClick={() => dispatch(showEnterForm())}
                                title="Войти"
                                icon={<EnterIcon />}
                            />
                        )}
                        {currentUser && (
                            <div>
                                <MenuButton
                                    id={"user-menu-button"}
                                    className={classes.UserMenuButton}
                                    onClick={() => {
                                        setIsUserMenuOpened(!isUserMenuOpened);
                                    }}
                                    title={currentUser.name}
                                    icon={<ProfileIcon />}
                                    spanStyle={{
                                        maxWidth: 86,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                                />
                                {isUserMenuOpened && (
                                    <UserMenu
                                        close={() => {
                                            setIsUserMenuOpened(false);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        <div className={classes.Divider} />
                        <div className={classes.UserMenu__items}>
                            <div className={classes.UserMenu__item}>
                                <MenuButton
                                    link={RouterConfig.FAVORITES}
                                    icon={<FavoriteBtnIcon hasNotifications={false} />}
                                    ariaLabel={"Избранное"}
                                />
                            </div>
                        </div>

                        <button
                            className={classes.PrimaryButton}
                            aria-label="Создать объявление"
                            onClick={() => {
                                router.push(RouterConfig.CREATE_OFFER);
                            }}
                        >
                            <PlusIcon />
                            <span>Создать объявление</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;