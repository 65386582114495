import { AutoIcon } from "@shared/ui/Icon/ui/Navigation/AutoIcon";
import { DocumentsIcon } from "@shared/ui/Icon/ui/Navigation/DocumentsIcon";
import { EstimateIcon } from "@shared/ui/Icon/ui/Navigation/EstimateIcon";
import { JobIcon } from "@shared/ui/Icon/ui/Navigation/JobIcon";
import { InsuranceIcon } from "@shared/ui/Icon/ui/Navigation/InsuranceIcon";
import { PropertyIcon } from "@shared/ui/Icon/ui/Navigation/PropertyIcon";
import { MarketsIcon } from "@shared/ui/Icon/ui/Navigation/MarketsIcon";
import { RouterConfig } from "@shared/lib/routerConfig";

export interface MenuItem {
    title: string;
    icon: any;
    link: string;
}

const Items: MenuItem[] = [
    {
        title: "Авто",
        icon: AutoIcon,
        link: RouterConfig.CATALOG
    },
    {
        title: "Работа",
        icon: JobIcon,
        link: RouterConfig.JOB
    },
    {
        title: "Страхование",
        icon: InsuranceIcon,
        link: RouterConfig.INSURANCE
    },
    {
        title: "Документы",
        icon: DocumentsIcon,
        link: RouterConfig.GENERATOR_DKP
    },
    {
        title: "Оценка авто",
        icon: EstimateIcon,
        link: "/analytics"
    },

    {
        title: "Недвижимость",
        icon: PropertyIcon,
        link: RouterConfig.PROPERTY
    },
    {
        title: "Маркетплейсы",
        icon: MarketsIcon,
        link: RouterConfig.MARKETPLACES
    }
];

export default Items;