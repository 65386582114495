import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import { ResendCodeProps } from "./types";
import { ResendCodeTexts, TIMER_DURATION } from "./constants";
import classes from "./ResendCode.module.scss";

export const ResendCode = ({ sendCode }: ResendCodeProps) => {
    const endTimeRef = useRef(DateTime.now().plus({ seconds: TIMER_DURATION }));
    const [timeLeft, setTimeLeft] = useState(TIMER_DURATION);
    const [canSendAgain, setCanSendAgain] = useState(false);
    const shouldShowTimer = !canSendAgain && timeLeft > 0;

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        sendCode();
        setCanSendAgain(false);
        endTimeRef.current = DateTime.now().plus({ seconds: TIMER_DURATION });
        setTimeLeft(TIMER_DURATION);
    };

    useEffect(() => {
        const updateTimer = () => {
            const now = DateTime.now();
            const diff = endTimeRef.current.diff(now, "seconds").seconds;
            if (diff <= 0) {
                setTimeLeft(0);
                setCanSendAgain(true);
                return;
            }
            setTimeLeft(Math.ceil(diff));
        };

        const timerId = setInterval(updateTimer, 1000);
        return () => clearInterval(timerId);
    }, []);

    return (
        <>
            {canSendAgain && (
                <p className={classes["resend-code"]}>
                    <span onClick={handleClick}>{ResendCodeTexts.SEND_TEXT}</span>
                </p>
            )}
            {shouldShowTimer && (
                <p className={classes["resend-code"]}>
                    {ResendCodeTexts.REPEAT_TEXT} {timeLeft} сек.
                </p>
            )}
        </>
    );
};