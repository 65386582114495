import React, { useEffect } from "react";
import classNames from "classnames";
import { IconWithLabel } from "@src/components/UI/Inputs/IconWithLabel";
import CancelMaterialIcon from "@src/components/Svg/CancelMaterialIcon";
import { Option, OptionsGroup } from "@src/components/UI/Inputs/SelectInput/types";
import { CloseIcon } from "@shared/ui/Icon/ui/Common/CloseIcon";
import { MobileSelectInputProps } from "./types";
import { EMPTY_OPTIONS } from "./constants";
import classes from "./MobileSelectInput.module.scss";

const MobileSelectInput = ({
    onClose,
    options,
    onChange,
    value,
    title,
    isMulti = false
}: MobileSelectInputProps) => {
    const [search, setSearch] = React.useState("");
    const [isFocused, setIsFocused] = React.useState(false);
    const filterBySearch = (el: Option) => el.label.toLowerCase().includes(search.toLowerCase());

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleOptionClick = (option: Option) => {
        const isSelected = isMulti && (value as Option[]).some((v) => v.value === option.value);
        const newValue = isMulti
            ? isSelected
                ? (value as Option[]).filter((v) => v.value !== option.value)
                : [...(value as Option[]), option]
            : option;
        onChange?.(newValue);
        onClose();
    };

    const handleCancelSearch = () => {
        setSearch("");
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    const isOptionSelected = (option: Option) => {
        return isMulti
            ? (value as Option[]).some((v) => v.value === option.value)
            : (value as Option)?.value === option.value;
    };

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.removeProperty("overflow");
        };
    }, []);

    return (
        <div className={classes.MobileSelectInput}>
            <div className={classes.Header}>
                <CloseIcon className={classes.CloseBtn} onClick={onClose} />
                <h3>{title}</h3>
            </div>
            <div className={classes.Search}>
                <input
                    value={search}
                    onChange={handleSearchChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {isFocused && <CancelMaterialIcon onClick={handleCancelSearch} />}
            </div>
            <div className={classes.Content}>
                <ul className={classes.OptionsList}>
                    {options.map((groupOrOption, groupIndex) => {
                        const groupOptions = (groupOrOption as OptionsGroup)?.options?.filter(
                            filterBySearch
                        );

                        return groupOptions?.length ? (
                            <li key={groupIndex} className={classes.GroupName}>
                                <label>{groupOrOption.label}</label>
                                <ul>
                                    {groupOptions.map((option, optionIndex) => (
                                        <li
                                            key={`option-${optionIndex}`}
                                            className={classNames(classes.Option, {
                                                [classes.Selected]: isOptionSelected(option)
                                            })}
                                            onClick={() => handleOptionClick(option)}
                                        >
                                            {option.alias ? (
                                                <IconWithLabel option={option} />
                                            ) : (
                                                option.label
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ) : groupOptions?.length === 0 ? (
                            <div key={`no-results-${groupIndex}`} className={classes.NoResults}>
                                {EMPTY_OPTIONS}
                            </div>
                        ) : (
                            <li
                                key={`option-${groupIndex}`}
                                className={classNames(classes.Option, {
                                    [classes.Selected]: isOptionSelected(groupOrOption as Option)
                                })}
                                onClick={() => handleOptionClick(groupOrOption as Option)}
                            >
                                {groupOrOption.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
export default MobileSelectInput;