import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const RefreshIcon: FC<IconProps> = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
        <path
            fill="#FFFFFF"
            fillRule="evenodd"
            d="M1.5445 8.1695C1.948 4.4322 5.0468 1.5 8.8406 1.5c2.1449 0 4.0705.9398 5.4094 2.43V3a.75.75 0 0 1 1.5 0v3a.7502.7502 0 0 1-.75.75h-3a.75.75 0 0 1 0-1.5h1.4002C12.3263 3.8738 10.6802 3 8.8406 3 5.8496 3 3.3611 5.3178 3.036 8.3305a.75.75 0 1 1-1.4914-.161Zm14.2458.8349a.7499.7499 0 0 1 .6651.826c-.4033 3.7374-3.5022 6.6696-7.296 6.6696-2.145 0-4.0705-.9398-5.4094-2.43V15a.75.75 0 0 1-1.5 0v-3a.7499.7499 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5H4.5997C5.6737 14.1262 7.3198 15 9.1594 15c2.991 0 5.4795-2.3179 5.8047-5.3305a.75.75 0 0 1 .8262-.6651Z"
            clipRule="evenodd"
        />
    </svg>
);