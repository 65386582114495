import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const HomeIcon: FC<IconProps> = ({ className, color }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.2412 16.2532H9.74591C9.38907 16.2532 9.05926 16.0628 8.88067 15.7537C8.70225 15.4446 8.70225 15.0637 8.88067 14.7546C9.05922 14.4453 9.38907 14.2549 9.74591 14.2549H15.2412C15.5981 14.2549 15.9279 14.4453 16.1065 14.7546C16.2849 15.0637 16.2849 15.4446 16.1065 15.7537C15.9279 16.0627 15.5981 16.2532 15.2412 16.2532Z"
            fill={color || "#1D1D1B"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4893 20H7.49788C6.8354 20 6.19996 19.7369 5.73161 19.2684C5.2631 18.8 5 18.1646 5 17.5021V10.2583C4.99915 9.30071 5.45582 8.40023 6.22891 7.83535H6.22908L10.6852 4.58807C11.2098 4.20595 11.8421 4 12.4912 4C13.1402 4 13.7725 4.20597 14.2971 4.58807L18.7532 7.83535C19.5283 8.39916 19.9869 9.29982 19.9872 10.2583V17.5021C19.9872 18.1646 19.7241 18.8 19.2556 19.2684C18.7872 19.7369 18.1518 20 17.4893 20ZM7.41037 9.45139C7.15197 9.63904 6.99882 9.93891 6.9983 10.2583L6.99828 17.5021C6.99828 17.7781 7.22182 18.0017 7.49781 18.0017H17.4893C17.6217 18.0017 17.7489 17.949 17.8425 17.8555C17.9362 17.7617 17.9888 17.6347 17.9888 17.5021V10.2583C17.9871 9.93803 17.832 9.638 17.5717 9.45139L13.1156 6.20411C12.9337 6.07325 12.7152 6.00286 12.4911 6.00286C12.2669 6.00286 12.0486 6.07325 11.8667 6.20411H11.8665L7.41037 9.45139Z"
            fill={color || "#1D1D1B"}
        />
    </svg>
);