import React, { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { useWindowSize } from "@shared/lib/hooks/useWindowSize";
import { PLACEHOLDER_ADS_TEXT } from "@shared/ui/AdBlock/constants";
import classes from "./AdBlock.module.scss";
import { AdBlockProps } from "./adblock-props.interface";

export const AdBlock = ({ blockId }: AdBlockProps) => {
    const pathname = usePathname();
    const { width } = useWindowSize();
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const shouldShowPlaceholder = isLoading || hasError;

    const handleRenderFail = () => {
        setIsLoading(false);
        setHasError(true);
    };

    useEffect(() => {
        handleRenderFail();
        if (typeof window !== "undefined") {
            const Ya = window.Ya;
            if (window.yaContextCb && Ya?.Context?.AdvManager) {
                window.yaContextCb.push(() => {
                    Ya?.Context?.AdvManager?.render({
                        blockId: blockId,
                        renderTo: `yandex_rtb_${blockId}`,
                        async: true,
                        onRenderComplete: () => {
                            setIsLoading(false);
                        },
                        onRenderFail: handleRenderFail
                    });
                });
            } else {
                handleRenderFail();
            }
        }
    }, [pathname, width]);

    return (
        <div id={`yandex_rtb_${blockId}`} className={classes.adblock}>
            {shouldShowPlaceholder && (
                <div className={classes.adblock__placeholder}>{PLACEHOLDER_ADS_TEXT}</div>
            )}
        </div>
    );
};