import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../components/UI/Buttons/PrimaryButton/PrimaryButton";
import LocationSnippet from "../../../components/UI/LocationSnippet/LocationSnippet";
import ContactButton from "../../../components/UI/Buttons/ContactButton/ContactButton";
import { numberWithSpaces } from "../../../utils/utils";
import {
    useAddToFavoritesMutation,
    useGetFavoritesOffersQuery,
    useRemoveFromFavoritesMutation
} from "@app/store/offersApi";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "@app/store/authSlice";
import HearBtnIcon from "../../../components/Svg/HearBtnIcon";
import { ProductShare } from "./ProductShare/ProductShare";
import { OfferIcon } from "@features/catalog";
import { CompareToggle } from "@features/compare/CompareToggle";
import { ProductPriceUsd } from "@features/product/ProductPriceUsd";
import { useGetCompareOffersQuery } from "@entities/compare";
import { ViewsIcon } from "@shared/ui/Icon/ui/Common/ViewsIcon";
import { getCurrencySymbol, toRelativeTime } from "@shared/lib/utils";
import Car from "@shared/lib/interfaces/car.interface";
import { ProductContactCardProps } from "./types";
import { COUNTER_VIEWS_TITLE, TEXT_ADD_TO_FAVORITES, TEXT_BUTTON_URL_BOARDS } from "./constants";
import classes from "./ProductContactCard.module.scss";

const ProductContactCard = ({ offer }: ProductContactCardProps) => {
    const isLivkiOffer = !offer.sourceId;
    const couldBeOpenedOnSource = !isLivkiOffer && offer.sourceId !== 5;
    const priceInCurrency = `${numberWithSpaces(offer.price.toString())} ${getCurrencySymbol(offer.sourceId)}`;

    const isAuthorized = useSelector(selectIsAuthenticated);
    const { data: favoritesData } = useGetFavoritesOffersQuery(
        {},
        {
            skip: !isAuthorized
        }
    );
    const { data: compareData } = useGetCompareOffersQuery(undefined, {
        skip: !isAuthorized
    });

    const [isFavorite, setIsFavorite] = useState(false);
    const [_isComparison, setIsComparison] = useState(false);
    const locationText = `${offer.city || ""}${offer.city && offer.address ? ", " : ""}${offer.address || ""}`;

    useEffect(() => {
        if (favoritesData && offer) {
            setIsFavorite(favoritesData.some((data: any) => data.id === offer.id));
        }
        if (compareData) {
            setIsComparison(compareData.offers.some((item: Car) => item.id === offer.id));
        }
    }, [offer, favoritesData, compareData]);

    const [addToFavorites, result] = useAddToFavoritesMutation();
    useEffect(() => {
        if (result.status === "rejected") {
            setIsFavorite(false);
        }
    }, [result]);
    const [removeFromFavorite] = useRemoveFromFavoritesMutation();

    return (
        <div className={classes.ProductContactCard}>
            <div className={classes.ProductContactCard__top}>
                <h1 className={classes["ProductContactCard__top-title"]}>{offer.title}</h1>
                <div className={classes["ProductContactCard__top-actions"]}>
                    <div className={classes.ProductContactCard__favorite}>
                        <a
                            className={classes["ProductContactCard__favorite-icon"]}
                            aria-label={TEXT_ADD_TO_FAVORITES}
                            onClick={(e) => {
                                e.preventDefault();
                                if (isFavorite) {
                                    removeFromFavorite({ id: offer.id });
                                } else {
                                    addToFavorites({ id: offer.id });
                                }
                                setIsFavorite(!isFavorite);
                            }}
                            href=""
                        >
                            <HearBtnIcon isSelected={isFavorite} />
                        </a>
                    </div>
                    <CompareToggle
                        className={classes.CompareOffer}
                        classNameIcon={classes.CompareOffer__icon}
                        classNameIconActive={classes["CompareOffer__icon--active"]}
                        offerId={offer.id}
                        isAuth={isAuthorized}
                    />
                    <ProductShare />
                </div>
            </div>
            <div className={classes.Price}>
                <span className={classes.Price__source}>{priceInCurrency}</span>
                <ProductPriceUsd
                    className={classes.Price__usd}
                    price={offer.price}
                    priceUsd={offer.priceUsd}
                    sourceId={offer.sourceId}
                    createdAt={offer.createdAt}
                    updatedAt={offer.updatedAt}
                    isUpdated={true}
                    offerUuid={offer.id}
                />
            </div>

            <div className={classes.Tags}>
                <div className={classes.Tags__specs}>
                    <span>{offer.year} г</span>
                    {offer.mileage && <span>{offer.mileage} км</span>}
                </div>
                <div className={classes.Tags__metrics} title={COUNTER_VIEWS_TITLE}>
                    <ViewsIcon />
                    <span>{offer.totalViews}</span>
                </div>
            </div>

            <div className={classes.Divider} />
            <div className={classes.Content}>
                <div className={classes.Info}>
                    <div className={classes.Props}>
                        {offer.props.slice(0, 5).map((item, index) => {
                            return (
                                <div className={classes.Prop} key={index}>
                                    <div className={classes.PropLabel}>{item.label}</div>
                                    <div className={classes.PropValue}>{item.value}</div>
                                </div>
                            );
                        })}
                    </div>

                    <div className={classes.DividerDesktop} />

                    <div className={`${classes.Location} ${classes.DesktopOnly}`}>
                        <LocationSnippet text={locationText} />
                    </div>

                    <div className={classes.DividerDesktop} />
                </div>

                <div className={classes.Buttons}>
                    {offer.phone && (
                        <div
                            style={{
                                marginBottom: "1em"
                            }}
                        >
                            <ContactButton
                                phone={offer.phone}
                                name={offer.contact}
                                isAuth={isAuthorized}
                            />
                        </div>
                    )}
                    {couldBeOpenedOnSource && (
                        <PrimaryButton
                            type="Large"
                            onClick={() => {
                                window.open(offer.url, "_blank");
                            }}
                            content={TEXT_BUTTON_URL_BOARDS}
                        />
                    )}
                </div>
            </div>
            <div className={classes.DividerMobile} />

            <div className={classes.Footer}>
                <OfferIcon className={classes.Footer_icon} sourceId={offer.sourceId} />
                <div className={classes.UpdatedTime}>{toRelativeTime(offer.createdAt)}</div>
            </div>
        </div>
    );
};
export default ProductContactCard;