import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../../UI/Inputs/TextInput/TextInput";
import { PrimaryButton } from "../../../UI/Buttons/PrimaryButton/PrimaryButton";
import { useSelector, useDispatch } from "react-redux";
import { showRestorePassForm } from "../../../../app/store/layoutSlice";
import { isValidEmail } from "../../../../utils/utils";
import { clearErrors, loginUser } from "../../../../app/store/authSlice";
import { AppState } from "../../../../app/store/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RouterConfig } from "@shared/lib/routerConfig";
import { EnterFormProps } from "./types";
import classes from "./EnterForm.module.scss";

export const REG_TEXT_FONT_SIZE = 10;

const EnterForm = ({ onClose }: EnterFormProps) => {
    const dispatch = useDispatch<ThunkDispatch<AppState, any, any>>();
    const isLoggingIn = useSelector((state: AppState) => state.auth.isLoggingIn);
    const errorMessage = useSelector((state: AppState) => state.auth.errorMessage);
    const emailMemory = useSelector((state: AppState) => state.auth.emailMemory);
    const textInputStyle = {
        paddingLeft: 10
    };

    useEffect(() => {
        dispatch(clearErrors());
    }, []);

    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = () => {
        const submit = handleSubmit((data) => {
            dispatch(
                loginUser({
                    email: data.EnterEmail,
                    password: data.EnterPassword
                })
            );
        });
        submit();
    };

    const handleEmailValidation = (email: string) => {
        const isValid = isValidEmail(email);
        return isValid ? isValid : "Неверный формат почты";
    };

    return (
        <div
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    onSubmit();
                }
            }}
            className={classes.EnterForm}
        >
            <div onClick={onClose} className={classes.CloseBtn}>
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.692 1.79511C14.1027 1.38445 14.1027 0.718653 13.692 0.307993C13.2814 -0.102656 12.6155 -0.102661 12.2049 0.307993L7 5.51289L1.79511 0.307993C1.38445 -0.102667 0.718647 -0.102661 0.307993 0.307993C-0.102661 0.718647 -0.102667 1.38445 0.307993 1.79511L5.51289 7L0.307993 12.2049C-0.102661 12.6155 -0.102667 13.2813 0.307993 13.692C0.718653 14.1027 1.38445 14.1027 1.79511 13.692L7 8.48711L12.2049 13.692C12.6156 14.1027 13.2814 14.1027 13.692 13.692C14.1027 13.2814 14.1027 12.6156 13.692 12.2049L8.48711 7L13.692 1.79511Z"
                        fill="#F86A6A"
                    />
                </svg>
            </div>
            <h3>Вход</h3>
            <form>
                <div className={classes.InputCont}>
                    <label>Электронная почта</label>
                    <Controller
                        control={control}
                        name="EnterEmail"
                        defaultValue={emailMemory}
                        rules={{
                            required: 'Заполните поле "Почта"',
                            validate: handleEmailValidation
                        }}
                        render={({ field }) => {
                            return (
                                <TextInput
                                    value={field.value}
                                    onChange={field.onChange}
                                    style={textInputStyle}
                                    placeholder="Введите электронную почту"
                                />
                            );
                        }}
                    />
                    {errors.EnterEmail && (
                        <span className={classes.Error}>
                            {errors?.EnterEmail?.message && errors?.EnterEmail?.message.toString()}
                        </span>
                    )}
                </div>

                <div
                    style={{
                        marginBottom: 10
                    }}
                    className={classes.InputCont}
                >
                    <label>Пароль</label>
                    <Controller
                        control={control}
                        name="EnterPassword"
                        rules={{
                            required: 'Заполните поле "Пароль"'
                        }}
                        render={({ field }) => {
                            return (
                                <TextInput
                                    type="password"
                                    value={field.value}
                                    onChange={field.onChange}
                                    style={textInputStyle}
                                    placeholder="Введите пароль"
                                />
                            );
                        }}
                    />
                    {errors.EnterPassword && (
                        <span className={classes.Error}>
                            {errors?.EnterPassword?.message &&
                                errors?.EnterPassword?.message.toString()}
                        </span>
                    )}
                </div>

                {errorMessage && (
                    <span className={classes.Error}>{errorMessage.slice(0, 140) + "..."}</span>
                )}

                <a
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(showRestorePassForm());
                    }}
                    className={classes.Link}
                    href="#"
                >
                    Забыли пароль?
                </a>
                <p
                    style={{
                        fontSize: REG_TEXT_FONT_SIZE,
                        marginTop: "1.5em"
                    }}
                >
                    Авторизуясь на сайте, я принимаю условия{" "}
                    <a
                        style={{
                            color: "#3B85F7",
                            textDecoration: "underline"
                        }}
                        target={"_blank"}
                        href={RouterConfig.PRIVACY_POLICY}
                    >
                        пользовательского соглашения
                    </a>{" "}
                    и даю согласие на обработку персональных данных в соответствии с
                    законодательством России и{" "}
                    <a
                        style={{
                            color: "#3B85F7",
                            textDecoration: "underline"
                        }}
                        target={"_blank"}
                        href={RouterConfig.PRIVACY_POLICY}
                    >
                        пользовательским соглашением.
                    </a>
                </p>
            </form>
            <PrimaryButton
                isLoading={isLoggingIn}
                onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
                style={{
                    marginTop: 22,
                    marginBottom: 18,
                    height: 56,
                    fontSize: 18
                }}
                content="Войти"
            />
        </div>
    );
};
export default EnterForm;