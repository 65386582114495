import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const AvByIcon: FC<IconProps> = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 30">
        <path
            fillRule="evenodd"
            fill="#0e88df"
            d="m66.337 4.606 5.298 11.287 5.413-11.287H82L71.406 26.488h-4.724l2.65-5.412-7.947-16.47h4.952zm-57.18-.46c5.087 0 9.27 3.916 9.27 9.098v8.638h-2.826l-1.583-.921c-1.357.921-3.052 1.382-4.748 1.382-5.087 0-9.27-3.916-9.27-9.099 0-5.182 4.07-9.098 9.157-9.098zm29.424 12.208a2.994 2.994 0 1 1 0 5.988 2.994 2.994 0 0 1 0-5.988zM47.22 0v5.527c1.38-.92 3.108-1.38 4.836-1.38 5.182 0 9.33 3.915 9.56 9.097 0 5.183-4.261 9.099-9.444 9.099-5.086 0-9.287-3.772-9.44-8.812l-.005-.287V0h4.493zM23.84 4.606l5.297 11.287 5.298-11.287h4.953L30.98 21.882h-3.8L18.772 4.606h5.068zM9.27 8.177c-2.713 0-4.974 2.303-4.974 5.067 0 2.765 2.26 5.068 4.974 5.068 2.713 0 4.861-2.303 4.974-5.068 0-2.764-2.26-5.067-4.974-5.067zm42.786 0c-2.763 0-5.066 2.303-5.066 5.067 0 2.765 2.303 5.068 5.066 5.068 2.764 0 4.953-2.303 5.067-5.068 0-2.764-2.303-5.067-5.067-5.067z"
        />
    </svg>
);