import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const ViewsIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={11}
        fill="none"
        viewBox="0 0 18 11"
    >
        <path
            fill="#3B85F7"
            d="M17.2294 5.3328C16.7071 4.4532 13.7658 0 9.0104 0 4.2549 0 1.3136 4.4533.7913 5.3328a.2496.2496 0 0 0 0 .275c.5223.8795 3.4636 5.3327 8.219 5.3327 4.7555 0 7.6969-4.4807 8.2191-5.3327.0275-.0825.0275-.1926 0-.275Zm-8.219 3.381c-1.7867 0-3.2436-1.4569-3.2436-3.2435 0-1.7867 1.4569-3.2436 3.2436-3.2436 1.7866 0 3.2435 1.457 3.2435 3.2436 0 1.7866-1.4569 3.2435-3.2435 3.2435Z"
        />
    </svg>
);