import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const TgLoginIcon: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.25" y="0.25" width="59.5" height="59.5" rx="5.75" fill="white" />
        <rect
            x="0.25"
            y="0.25"
            width="59.5"
            height="59.5"
            rx="5.75"
            stroke="#E3E6EB"
            strokeWidth="0.5"
        />
        <path
            d="M46 30C46 38.836 38.836 46 30 46C21.164 46 14 38.836 14 30C14 21.164 21.164 14 30 14C38.836 14 46 21.164 46 30Z"
            fill="url(#paint0_linear_5198_3315)"
        />
        <path
            d="M20.2912 29.7793C25.7434 27.3808 32.7985 24.4566 33.7728 24.0518C36.3273 22.9925 37.1112 23.1953 36.7204 25.5416C36.4396 27.2279 35.6299 32.8104 34.9841 36.2853C34.6011 38.346 33.7416 38.5902 32.3906 37.6986C31.7409 37.2696 28.461 35.1005 27.7488 34.5911C27.0991 34.127 26.2029 33.5685 27.3268 32.4687C27.727 32.0772 30.3486 29.5734 32.3914 27.6249C32.6589 27.3691 32.3227 26.9487 32.0139 27.1538C29.2605 28.9798 25.4431 31.514 24.9572 31.844C24.2232 32.3424 23.5181 32.5709 22.2529 32.2074C21.2967 31.9329 20.363 31.6053 19.9995 31.4805C18.5994 31 18.9317 30.3776 20.2912 29.7793Z"
            fill="white"
        />
        <defs>
            <linearGradient
                id="paint0_linear_5198_3315"
                x1="18.6864"
                y1="18.6864"
                x2="41.3136"
                y2="41.3136"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#33BEF0" />
                <stop offset="1" stopColor="#0A85D9" />
            </linearGradient>
        </defs>
    </svg>
);