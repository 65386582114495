import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { ArrowUpIcon } from "@shared/ui/Icon/ui/Common/ArrowUpIcon";
import {
    BOTTOM_SCROLL_THRESHOLD,
    SCROLL_ACTIVATION_THRESHOLD,
    TOP_SCROLL_THRESHOLD
} from "./constants";
import classes from "./ScrollButton.module.scss";

export const ScrollButton = () => {
    const [stateButton, setStateButton] = useState({
        showScroll: false,
        scrollDirection: "up",
        scrollInitiated: false
    });
    const requestRef = useRef<number>();

    const handleScroll = (direction: string) => {
        window.scrollTo({
            top: direction === "up" ? 0 : document.body.scrollHeight,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const checkScroll = () => {
            const scrollY = window.scrollY;
            const atTop = scrollY <= TOP_SCROLL_THRESHOLD;
            const atBottom =
                window.innerHeight + scrollY >=
                document.body.scrollHeight - BOTTOM_SCROLL_THRESHOLD;

            setStateButton((prev) => ({
                ...prev,
                showScroll: scrollY > SCROLL_ACTIVATION_THRESHOLD || prev.showScroll,
                scrollDirection: atTop ? "down" : atBottom ? "up" : "up",
                scrollInitiated: scrollY > SCROLL_ACTIVATION_THRESHOLD || prev.scrollInitiated
            }));

            requestRef.current = requestAnimationFrame(checkScroll);
        };

        requestRef.current = requestAnimationFrame(checkScroll);

        return () => {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);

    return stateButton.scrollInitiated ? (
        <div
            className={classNames(classes["scroll-button"], {
                [classes["scroll-button__active"]]: stateButton.showScroll,
                [classes["scroll-button__up"]]: stateButton.scrollDirection === "up",
                [classes["scroll-button__down"]]: stateButton.scrollDirection === "down"
            })}
            onClick={() => handleScroll(stateButton.scrollDirection)}
        >
            <ArrowUpIcon
                className={classNames(classes["scroll-button__icon"], {
                    [classes["scroll-button__icon--rotate"]]: stateButton.scrollDirection === "down"
                })}
            />
        </div>
    ) : null;
};