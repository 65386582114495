import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { configApi } from "@shared/lib/configApi";

export const useCopyWithSource = () => {
    const pathname = usePathname();

    useEffect(() => {
        if (typeof window === "undefined") {
            return;
        }

        const handleCopy = (event: ClipboardEvent) => {
            const selection = window.getSelection();
            const pageUrl = `${configApi.API_PUBLIC_DOMAIN}${pathname}`;

            if (selection) {
                const selectedText = selection.toString();
                const copyText = `${selectedText}\n\nИсточник: ${pageUrl} Likvi.com`;

                event.clipboardData?.setData("text/plain", copyText);
                event.preventDefault();
            }
        };

        document.addEventListener("copy", handleCopy);

        return () => {
            document.removeEventListener("copy", handleCopy);
        };
    }, [pathname]);
};