import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const MyCarIcon: FC<IconProps> = ({ className, color = "#fff" }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 24">
        <path
            fillRule="evenodd"
            fill={color}
            d="M30.628 2.915V0a7.57 7.57 0 0 1-7.574 0v2.915a7.57 7.57 0 0 0 7.574 0M3.377 17.97v-5.958c0-2.123 1.134-3.305 2.533-3.305 1.35 0 2.363 1.062 2.363 3.305v5.958h3.28v-5.958c0-2.098 1.086-3.305 2.533-3.305 1.351 0 2.364 1.086 2.364 3.305v5.958h3.377v-6.272c0-3.907-2.074-5.91-4.969-5.91-1.857 0-3.16.724-4.197 1.93-.989-1.326-2.243-1.93-3.762-1.93-1.424 0-2.726.676-3.522 1.665V6.03H0v11.94zm23.403-3.33-2.726-8.61h-3.57l4.632 12.759L23.163 24h3.473l6.561-17.97H29.58zm12.76 3.62c2.267 0 4.149-1.013 5.186-2.629L42.24 13.75a3.1 3.1 0 0 1-2.629 1.423c-1.688 0-3.039-1.35-3.039-3.184 0-1.81 1.35-3.16 3.04-3.16 1.085 0 2.05.53 2.628 1.423l2.485-1.881c-1.062-1.616-2.919-2.63-5.186-2.63-3.69 0-6.344 2.75-6.344 6.272 0 3.497 2.653 6.247 6.344 6.247m11.243-.048c1.712 0 2.99-.7 3.811-1.64v1.399h3.377V6.03h-3.377v1.4c-.82-.942-2.099-1.641-3.811-1.641-3.28 0-5.644 2.701-5.644 6.199s2.364 6.223 5.644 6.223m.772-2.99c-1.713 0-3.04-1.376-3.04-3.233 0-1.833 1.327-3.232 3.04-3.232 1.712 0 3.04 1.399 3.04 3.232 0 1.857-1.328 3.232-3.04 3.232m12.077-2.196v4.945h-3.377V6.03h3.377v1.616c.892-1.278 2.122-1.881 3.714-1.881.193 0 .29 0 .483.024v3.377c-.314-.024-.555-.024-.796-.024-1.93 0-3.401 1.35-3.401 3.883"
            clipRule="evenodd"
        />
    </svg>
);