import React, { useEffect, useRef, useState } from "react";
import { ProductDescriptionProps } from "./types";
import classes from "./ProductDescription.module.scss";

const ProductDescription = ({ offer }: ProductDescriptionProps) => {
    const pRef = useRef(null);

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpandRequired, setIsExpandRequired] = useState(false);
    useEffect(() => {
        if (pRef.current) {
            const p = pRef.current as HTMLParagraphElement;
            if (p.scrollHeight > p.clientHeight) {
                setIsExpandRequired(true);
            }
        }
    }, [pRef]);

    useEffect(() => {
        if (pRef.current) {
            const p = pRef.current as HTMLParagraphElement;
            if (isExpanded) {
                p.style.maxHeight = "5000px";
            } else {
                p.style.maxHeight = "54px";
            }
        }
    }, [isExpanded]);

    return (
        <div className={classes.ProductDescription}>
            <h3>Описание</h3>

            <p
                className={`${isExpanded ? classes.Expanded : undefined}`}
                ref={pRef}
                style={{
                    whiteSpace: "pre-line"
                }}
            >
                {offer.description}
            </p>
            {isExpandRequired && (
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        setIsExpanded(!isExpanded);
                    }}
                    href="#"
                >
                    {isExpanded ? "Свернуть" : "Показать весь текст..."}
                </a>
            )}
        </div>
    );
};
export default ProductDescription;