export class SeoTargets {
    constructor() {}

    public static getYm() {
        const isClient = typeof window !== "undefined";
        // @ts-ignore
        const ym = isClient ? window["ym"] : null;
        return ym;
    }

    public static enterByPhoneEvent() {
        const ym = this.getYm();
        if (ym) {
            ym(93537510, "reachGoal", "vxod_ili_registrazia");
        }
    }

    public static registrationEvent() {
        const ym = this.getYm();
        if (ym) {
            ym(93537510, "reachGoal", "sozdat_akkaunt");
        }
    }

    public static showMoreEvent() {
        const ym = this.getYm();
        if (ym) {
            ym(93537510, "reachGoal", "pokazat_eshe");
        }
    }
}