import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const EstimateIcon: FC<IconProps> = ({ className, color }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4ZM12 6C10.2761 6 9.21899 6.28101 8 7.5C6.78101 8.71899 6 10.2761 6 12C6 13.7239 6.78101 15.281 8 16.5C9.21899 17.719 10.2761 18 12 18C13.7239 18 15.281 17.219 16.5 16C17.719 14.781 18 13.7239 18 12C18 10.2761 17.719 9.21899 16.5 8C15.281 6.78101 13.7239 6 12 6Z"
            fill={color || "#1D1D1B"}
        />
        <path
            d="M9.493 16V8H12.697C13.72 8 14.524 8.229 15.11 8.686C15.703 9.136 16 9.802 16 10.686C16 11.562 15.703 12.229 15.11 12.686C14.524 13.136 13.72 13.36 12.697 13.36H10.294L10.939 12.674V16H9.493ZM10.939 12.834L10.294 12.16H12.686C13.279 12.16 13.735 12.034 14.054 11.783C14.38 11.523 14.543 11.162 14.543 10.697C14.543 10.232 14.38 9.878 14.053 9.634C13.735 9.383 13.279 9.257 12.686 9.257H10.294L10.939 8.526V12.834ZM8.381 14.811V14.011H13.386V14.811H8.381Z"
            fill={color || "#1D1D1B"}
        />
    </svg>
);