import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const AutoRuIcon: FC<IconProps> = ({ className }) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 20">
        <path
            fill="#DB3727"
            fillRule="evenodd"
            d="M7.614 3.396c2.208 0 3.952.849 4.446 1.99v-1.58h3.575V18.88H12.06V17.3c-.494 1.112-2.238 1.99-4.446 1.99C3.604 19.29 0 16.07 0 11.358c0-4.713 3.603-7.962 7.614-7.962zM8.05 15.34c1.83 0 3.429-.878 3.894-1.99V9.338c-.465-1.142-2.063-1.99-3.894-1.99-2.558 0-4.33 1.639-4.33 4.01 0 2.37 1.772 3.981 4.33 3.981zM19.79 3.805h3.69v8.138c0 2.167 1.367 3.396 3.169 3.396 1.569 0 3.051-.966 3.312-2.283v-9.25h3.691V18.88h-3.575v-1.522c-.406 1.112-1.83 1.932-3.98 1.932-3.081 0-6.307-2.05-6.307-6.733V3.805m27.985 13.73c-.698.995-2.034 1.756-3.72 1.756-3.312 0-4.91-1.903-4.91-5.152V7.757h-2.558v-1.2L41.73 0h1.133v3.805H47.6v3.952h-4.766v5.563c0 1.492.872 2.02 1.86 2.02 1.017 0 1.628-.381 2.296-1.142l.784 3.336m10.375 1.757c-4.243 0-7.904-3.367-7.904-7.933a7.903 7.903 0 0 1 7.904-7.962 7.902 7.902 0 0 1 7.904 7.962c0 4.566-3.66 7.933-7.904 7.933zm0-3.952c2.325 0 4.184-1.61 4.184-3.981 0-2.371-1.86-4.01-4.184-4.01-2.325 0-4.185 1.639-4.185 4.01 0 2.37 1.86 3.981 4.185 3.981zm12.816-1.141c1.423 0 2.527 1.112 2.527 2.546 0 1.435-1.104 2.547-2.527 2.547-1.425 0-2.529-1.112-2.529-2.547 0-1.434 1.104-2.546 2.529-2.546M86.25 7.962a3.599 3.599 0 0 0-1.975-.615c-1.715 0-2.82 1.084-3.11 2.46v9.074h-3.691V3.805h3.575v1.99c.436-1.404 1.627-2.399 3.313-2.399 1.104 0 2.034.527 2.412 1.141l-.524 3.425m3.051-4.157h3.69v8.138c0 2.167 1.367 3.396 3.169 3.396 1.569 0 3.05-.966 3.312-2.283v-9.25h3.691V18.88H99.59v-1.522c-.407 1.112-1.83 1.932-3.982 1.932-3.08 0-6.306-2.05-6.306-6.733V3.805"
        />
    </svg>
);