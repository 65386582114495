import React from "react";

/* eslint-disable react/display-name */
export default React.memo(() => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0C3.59986 0 0 3.59986 0 8C0 12.4001 3.59986 16 8 16C12.4001 16 16 12.4001 16 8C16 3.59986 12.4001 0 8 0ZM8 14.48C4.44 14.48 1.52007 11.6 1.52007 8.00007C1.52007 4.44007 4.40007 1.52014 8 1.52014C11.5999 1.52014 14.4799 4.44007 14.4799 8.00007C14.4799 11.5601 11.56 14.48 8 14.48ZM10.7999 7.2H8.79993V5.2C8.79993 4.75999 8.43999 4.40007 8 4.40007C7.56001 4.40007 7.20007 4.76001 7.20007 5.2V7.2H5.20007C4.76006 7.2 4.40014 7.55994 4.40014 7.99993C4.40014 8.43992 4.76008 8.79986 5.20007 8.79986H7.20007V10.7999C7.20007 11.2399 7.56001 11.5998 8 11.5998C8.43999 11.5998 8.79993 11.2399 8.79993 10.7999V8.79986H10.7999C11.2399 8.79986 11.5999 8.43992 11.5999 7.99993C11.5999 7.55994 11.2399 7.2 10.7999 7.2Z"
                fill="white"
            />
        </svg>
    );
});