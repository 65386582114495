import React from "react";
import { BoardsSprite } from "@shared/ui/Icon/ui/Boards/BoardsSprite";
import { COLOR_MAP_BOARDS } from "./constants";
import { OfferIconProps } from "./types";

const OfferIcon = ({ sourceId = 0, className }: OfferIconProps) => {
    const IconComponent = BoardsSprite[sourceId] || BoardsSprite[0];
    const color = COLOR_MAP_BOARDS[sourceId];
    return <IconComponent className={className} color={color} />;
};

export default OfferIcon;