import React, { useEffect } from "react";
import { ServiceNames } from "@features/auth/ChangePasswordForm/constants";
import {
    VK_AUTH_CLIENT_ID,
    VK_AUTH_REDIRECT_URI,
    YANDEX_AUTH_CLIENT_ID,
    YANDEX_AUTH_REDIRECT_URI,
    YANDEX_AUTH_RESPONSE_TYPE
} from "@features/auth/constants";
import { VkLoginIcon } from "@shared/ui/Icon/ui/AuthServices/VkLoginIcon";
import { YaLoginIcon } from "@shared/ui/Icon/ui/AuthServices/YaLoginIcon";
import * as VKID from "@vkid/sdk";
import { TgLogin } from "./TgLogin";
import classes from "./SocialAuth.module.scss";

export const SocialAuth = () => {
    const onLoginClick = (serviceName: ServiceNames) => {
        if (serviceName === ServiceNames.VK) {
            VKID.Auth.login();
        }
        if (serviceName === ServiceNames.YANDEX) {
            const url = window.YaAuthSuggest?.getOauthUrl({
                client_id: YANDEX_AUTH_CLIENT_ID,
                response_type: YANDEX_AUTH_RESPONSE_TYPE,
                redirect_uri: YANDEX_AUTH_REDIRECT_URI
            });
            window.location.href = url;
        }
    };

    const handleSocialsLogin = (serviceName: ServiceNames) => (e: React.MouseEvent) => {
        e.preventDefault();
        onLoginClick(serviceName);
    };

    useEffect(() => {
        const vkInit = async () => {
            VKID.Config.init({
                app: VK_AUTH_CLIENT_ID,
                redirectUrl: VK_AUTH_REDIRECT_URI,
                scope: "email phone"
            });
        };

        vkInit();
    }, []);

    return (
        <div className={classes["social-auth"]}>
            <TgLogin />
            <VkLoginIcon
                className={classes["social-auth__item"]}
                onClick={handleSocialsLogin(ServiceNames.VK)}
            />
            <YaLoginIcon
                className={classes["social-auth__item"]}
                onClick={handleSocialsLogin(ServiceNames.YANDEX)}
            />
        </div>
    );
};