import React from "react";
import { RouterConfig } from "@shared/lib/routerConfig";
import classes from "./PrivacyInfo.module.scss";

export const PrivacyInfo = () => {
    return (
        <p className={classes["privacy-info"]}>
            Авторизуясь на сайте, я принимаю условия{" "}
            <a
                className={classes["privacy-info__link"]}
                target={"_blank"}
                rel="noopener noreferrer"
                href={RouterConfig.PRIVACY_POLICY}
            >
                пользовательского соглашения
            </a>{" "}
            и даю согласие на обработку персональных данных в соответствии с законодательством
            России и{" "}
            <a
                className={classes["privacy-info__link"]}
                target={"_blank"}
                rel="noopener noreferrer"
                href={RouterConfig.PRIVACY_POLICY}
            >
                пользовательским соглашением.
            </a>
        </p>
    );
};